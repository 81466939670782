import styled from 'styled-components';

const ChipInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ChipContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const CategoryInput = styled.div`
  display: flex;
  cursor: pointer;
  &:hover {
    fieldset {
      border: 1px solid ${props => props.theme.palette.primary.light};
    }
  }
  .disabledTextField {
    pointer-events: none;
    width: 100%;
  }
`;

const ProductFormStyles = { ChipInput, ChipContainer, CategoryInput };

export default ProductFormStyles;
