import { useMutation, useQuery } from 'react-query';
import { apiRoutes } from '../constants/api.constant';
import apiClient from './api.service';
import { ITablePayload } from './types';

export const usePaymentsTableData = (payload: ITablePayload): any => {
  return useQuery([apiRoutes.PAYMENTS_LIST, 'usePaymentsTableData', payload], () => getPaymentsTableData(payload));
};

export const usePaymentsDetail = (purchaseId: number): any => {
  return useQuery([apiRoutes.PAYMENTS_LIST, 'usePaymentsDetail', purchaseId], () => getPaymentsDetail(purchaseId), {
    enabled: !!purchaseId,
  });
};

export const useSavePayments = () => useMutation(savePayments);
export const useUpdatePayments = () => useMutation(updatePayments);
export const useDeletePayments = () => useMutation(deletePayments);

const getPaymentsTableData = async (payload: any) => await apiClient.post(apiRoutes.PAYMENTS_LIST, payload);
const getPaymentsDetail = async (purchaseId: number) => await apiClient.get(`${apiRoutes.PAYMENTS}/${purchaseId}`);
const savePayments = async (data: any) => await apiClient.post(apiRoutes.PAYMENTS, data);
const updatePayments = async (data: any) => await apiClient.post(`${apiRoutes.PAYMENTS}/${data.formId}`, data);
const deletePayments = async (purchaseId: number) => await apiClient.delete(`${apiRoutes.PAYMENTS}/${purchaseId}`);
