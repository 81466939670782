import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Grid, MenuItem, Select, TableCell, TableRow, TextField, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import MainCard from 'components/MainCard';
import IndexTable from 'components/index-table';
import { STATUS_CODES } from 'constants/api.constant';
import { endOfDay, startOfMonth } from 'date-fns';
import dayjs from 'dayjs';
import useReportHelper from 'hooks/useReportHelper';
import { useGetFormattedProducts } from 'pages/bills-form/helpers';
import { GlobalStyle } from 'pages/bills-form/styles';
import { useState } from 'react';
import { useSalesReport } from 'services/reports.service';
import { timeFormat } from 'utils/dateFormat';
import { currencyFormat } from 'utils/numberFormat';
import ReportStyles from './styles';

const reportPayload = {
  from: startOfMonth(new Date()).toISOString(),
  to: endOfDay(new Date()).toISOString(),
  groupBy: 'category',
  payment_mode: 'All',
  conditions: [],
};
export const tableConfig = [
  {
    id: 'sno',
    align: 'left',
    label: 'S.No',
    hasSort: false,
    customCell: true,
  },
  {
    id: 'item',
    align: 'left',
    label: 'Item',
    hasSort: true,
    customCell: true,
  },
  {
    id: 'amount',
    align: 'right',
    label: 'Bill Amount',
    hasSort: true,
  },
];

const SalesReport = () => {
  const [payload, setPayload] = useState(reportPayload);
  const salesReport = useSalesReport();
  const [tableData, setTableData] = useState<any>();
  const { productMap, categoryMap } = useGetFormattedProducts();
  const { exportTable } = useReportHelper();

  const onDateChange = (dVal: dayjs.Dayjs | null, key: string) => {
    setPayload({ ...payload, [key]: dVal?.toISOString() });
  };

  const onSelectionChange = (key: string) => (e: any) => {
    setPayload({ ...payload, [key]: e.target.value });
  };

  const fetchReport = () => {
    salesReport.mutate(payload, {
      onSuccess: (response: any) => {
        setTableData(response);
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          console.log(error);
        }
      },
    });
  };

  const getCustomCell = (column: any, row: any, rowIndex: number, columnIndex: number) => {
    if (column.id === 'sno') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {rowIndex + 1}
        </TableCell>
      );
    }
    if (column.id === 'item') {
      const group = tableData?.payload?.groupBy;
      const map = group === 'category' ? categoryMap[row.cat_id]?.name : productMap[row.pid]?.name;
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {map}
        </TableCell>
      );
    }
  };

  return (
    <>
      <GlobalStyle />
      <Grid container justifyContent="space-between" alignItems="center" sx={{ pb: 2, gap: 1 }}>
        <MuiBreadcrumbs aria-label="breadcrumb">
          <Typography color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
            Reports
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            Sales
          </Typography>
        </MuiBreadcrumbs>
        <Grid item>
          <Grid item container alignItems="center" gap={1}>
            <MobileDateTimePicker
              label="From"
              inputFormat="DD MMM, YYYY hh:mm A"
              value={dayjs(payload.from)}
              onChange={v => null}
              onAccept={v => onDateChange(v, 'from')}
              renderInput={params => <TextField {...params} />}
            />
            <MobileDateTimePicker
              label="To"
              inputFormat="DD MMM, YYYY hh:mm A"
              value={dayjs(payload.to)}
              onChange={v => null}
              onAccept={v => onDateChange(v, 'to')}
              renderInput={params => <TextField {...params} />}
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Group By</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Group By"
                value={payload.groupBy}
                onChange={onSelectionChange('groupBy')}
              >
                <MenuItem value={'category'}>Category</MenuItem>
                <MenuItem value={'products'}>Products</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: 150 }}>
              <InputLabel id="demo-simple-select_2-label">Payment Mode</InputLabel>
              <Select
                labelId="demo-simple-select_2-label"
                id="demo-simple-select_2"
                label="Payment Mode"
                value={payload.payment_mode}
                onChange={onSelectionChange('payment_mode')}
              >
                <MenuItem value={'All'}>All</MenuItem>
                <MenuItem value={'C'}>Cash</MenuItem>
                <MenuItem value={'U'}>UPI</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" size="small" startIcon={<FilterListIcon />} onClick={() => fetchReport()}>
              Filter
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<FileDownloadIcon />}
              onClick={() => exportTable('salesReport')}
            >
              Export
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <MainCard>
        <IndexTable
          tableId="salesReport"
          isFetching={salesReport.isLoading}
          columns={tableConfig}
          data={tableData}
          payload={payload}
          getCustomCell={getCustomCell}
          onPayloadChange={(newPayload: any) => setPayload(newPayload)}
          hasPagination={false}
          clearFilters={() => setPayload(reportPayload)}
          tableHeader={
            <>
              {tableData && (
                <TableRow className="totalReportInfo">
                  <TableCell colSpan={2} align="left">
                    <ReportStyles.Label>From:</ReportStyles.Label>
                    <ReportStyles.Value>{timeFormat(tableData?.payload?.from)}</ReportStyles.Value>

                    <ReportStyles.Label>To:</ReportStyles.Label>
                    <ReportStyles.Value>{timeFormat(tableData?.payload?.to)}</ReportStyles.Value>

                    <ReportStyles.Label>Group:</ReportStyles.Label>
                    <ReportStyles.Value>{tableData?.payload?.groupBy}</ReportStyles.Value>
                    <ReportStyles.Label>Payment Mode:</ReportStyles.Label>
                    <ReportStyles.Value>{tableData?.payload?.payment_mode}</ReportStyles.Value>
                  </TableCell>
                  <TableCell padding={'none'} align="right">
                    <ReportStyles.Label>Total:</ReportStyles.Label>
                    <ReportStyles.Value>{currencyFormat(tableData?.total?.amount || 0)}</ReportStyles.Value>
                  </TableCell>
                </TableRow>
              )}
            </>
          }
        />
      </MainCard>
    </>
  );
};

export default SalesReport;
