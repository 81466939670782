import { loaderTimeout } from 'configs/style.config';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { authSelector } from '../store/auth';
import styles from './styles';

const ProtectedRoutes = () => {
  const { loggedIn, loginVerified } = useSelector(authSelector);
  const [isResolved, setIsResolved] = useState(false);
  if (loggedIn) {
    if (!isResolved) {
      setTimeout(() => {
        setIsResolved(true);
      }, loaderTimeout);
      return <SuspenseLoader />;
    }
    return <Outlet />;
  } else if (!loggedIn && !loginVerified) {
    return <SuspenseLoader />;
  } else {
    return <Navigate to="/login" />;
  }
};

export const SuspenseLoader = () => (
  <styles.AppLoaderContainer>
    <span className="progress">
      <span className="color">
        <img src="/assets/images/logo.png" alt="logo" />
      </span>
      <span className="textInfo">loading please wait.....</span>
    </span>
  </styles.AppLoaderContainer>
);
export default ProtectedRoutes;
