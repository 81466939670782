import React, { Suspense, useRef } from 'react';
import { useRoutes } from 'react-router-dom';

import { STATUS_CODES } from 'constants/api.constant';
import globalConstants, { UserRole } from 'constants/global.constants';
import Database, { broadToken } from 'database/database';
import PageError from 'pages/app-states/PageError';
import PageNotFound from 'pages/app-states/PageNotFound';
import Attendance from 'pages/attendance';
import Bills from 'pages/bills';
import BillForm from 'pages/bills-form';
import Dashboard from 'pages/dashboard';
import Payments from 'pages/payments';
import Products from 'pages/products';
import Purchase from 'pages/purchase';
import SalesReport from 'pages/reports/sales';
import SupplierReport from 'pages/reports/supplier';
import TransactionReport from 'pages/reports/transaction';
import Suppliers from 'pages/suppliers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useOnlineStatus } from 'utils/useOnlineStatus';
import { appRoutes } from '../constants/routes.constant';
import Landing from '../pages/landing';
import Login from '../pages/login';
import { useGetUserInfo } from '../services/auth.service';
import { useAppDispatch } from '../store';
import { authSelector, setAuthDetails } from '../store/auth';
import ProtectedRoutes, { SuspenseLoader } from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import RoleBasedRoute from './RoleBasedRoute';

const channel = new BroadcastChannel('broadChannel');

const Navigation = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const isOnline = useOnlineStatus();
  const userInfoApi = useGetUserInfo({ enabled: false });
  const isResolved = useRef(0);
  const { loggedIn } = useSelector(authSelector);

  const getUserInfo = async () => {
    const userInfo = await userInfoApi.refetch();
    if (userInfo.isError) {
      const status = (userInfo.error as any)?.status as number;
      if (status === STATUS_CODES.UNAUTHORIZED) {
        localStorage.removeItem(globalConstants.TOKEN_STORAGE_KEY);
        dispatch(setAuthDetails({ loggedIn: false, loginVerified: true, token: '' }));
      }
    } else {
      const userData = userInfo.data;
      dispatch(setAuthDetails({ loggedIn: true, loginVerified: true, ...userData }));
    }
  };

  const getLocalUserInfo = async (token: string) => {
    const db = await Database.get();
    const user = await db.users
      .findOne({
        selector: {
          token: token,
        },
      })
      .exec();
    if (!!user) {
      const userData = user.toJSON();
      dispatch(setAuthDetails({ loggedIn: true, loginVerified: true, ...userData }));
    } else {
      localStorage.removeItem(globalConstants.TOKEN_STORAGE_KEY);
      dispatch(setAuthDetails({ loggedIn: false, loginVerified: true, token: '' }));
    }
  };

  useEffect(() => {
    channel.postMessage({ type: 'leader_selection', token: broadToken });
    channel.onmessage = data => {
      if (data.data.type === 'leader_selection' && data.data.token !== broadToken) {
        window.open('https://www.google.com/', '_self');
      }
    };
    const token = localStorage.getItem(globalConstants.TOKEN_STORAGE_KEY);
    if (!token) {
      dispatch(setAuthDetails({ loggedIn: false, loginVerified: true }));
    } else {
      dispatch(setAuthDetails({ token: token }));
      if (!isResolved.current) {
        isResolved.current = 1;
        if (isOnline) {
          getUserInfo();
        } else {
          getLocalUserInfo(token);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!!isOnline) {
      syncProducts();
    }
  }, [isOnline, loggedIn]);

  const syncProducts = async () => {
    if (!!loggedIn) {
      await Database.get();
      Database.syncProducts();
    }
  };

  const routesPath = [
    {
      path: appRoutes.LANDING,
      element: <ProtectedRoutes />,
      children: [
        {
          path: appRoutes.LANDING,
          element: <Landing />,
          children: [
            {
              path: appRoutes.LANDING,
              element: <RoleBasedRoute allowedRoles={[UserRole.EMPTY]} component={<></>}></RoleBasedRoute>,
              index: true,
            },
            {
              path: appRoutes.DASHBOARD,
              element: <RoleBasedRoute allowedRoles={[UserRole.ADMIN]} component={<Dashboard />}></RoleBasedRoute>,
            },
            {
              path: appRoutes.SALES_REPORT,
              element: <RoleBasedRoute allowedRoles={[UserRole.ADMIN]} component={<SalesReport />}></RoleBasedRoute>,
            },
            {
              path: appRoutes.TRANSACTION_REPORT,
              element: (
                <RoleBasedRoute allowedRoles={[UserRole.ADMIN]} component={<TransactionReport />}></RoleBasedRoute>
              ),
            },
            {
              path: appRoutes.SUPPLIER_REPORT,
              element: <RoleBasedRoute allowedRoles={[UserRole.ADMIN]} component={<SupplierReport />}></RoleBasedRoute>,
            },
            { path: appRoutes.PRODUCTS, element: <Products /> },
            {
              path: appRoutes.PAYMENTS,
              element: <RoleBasedRoute allowedRoles={[UserRole.ADMIN]} component={<Payments />}></RoleBasedRoute>,
            },
            {
              path: appRoutes.PURCHASE,
              element: <RoleBasedRoute allowedRoles={[UserRole.ADMIN]} component={<Purchase />}></RoleBasedRoute>,
            },
            { path: appRoutes.SUPPLIERS, element: <Suppliers /> },
            { path: appRoutes.BILLS, element: <Bills /> },
            { path: appRoutes.NEW_BILL, element: <BillForm /> },
            { path: appRoutes.ATTENDANCE, element: <Attendance /> },
          ],
        },
      ],
    },
    {
      path: appRoutes.LOGIN,
      element: <PublicRoutes />,
      children: [{ path: appRoutes.LOGIN, element: <Login />, index: true }],
    },
    {
      path: appRoutes.NOT_FOUND,
      element: <PageNotFound />,
    },
    {
      path: appRoutes.ERROR,
      element: <PageError />,
    },
  ];

  const MyRoutes = () => useRoutes(routesPath);

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <MyRoutes />
    </Suspense>
  );
};

export default Navigation;
