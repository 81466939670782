import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Button, ButtonBase, CircularProgress, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { RupeeIcon } from 'assets/icons/rupee';
import { UpiICon } from 'assets/icons/upi';
import MainCard from 'components/MainCard';
import { STATUS_CODES, apiRoutes } from 'constants/api.constant';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { fetchOrderNo_db, useSaveBill } from 'services/bills.service';
import { authSelector } from 'store/auth';
import { billsSelector, setOrderNo } from 'store/bills';
import { currencyFormat } from 'utils/numberFormat';
import useKeyboardShortcut from 'utils/useKeyboardShortcut';
import BillPrint from './bill-print';
import { useGetFormattedProducts } from './helpers';
import FormStyles from './styles';

const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;

const BillForm = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { id } = useSelector(authSelector);
  const { orderNo } = useSelector(billsSelector);
  const saveBill = useSaveBill();
  const { formattedProducts, categoryList } = useGetFormattedProducts();

  const [billedProducts, setBilledProducts] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [focusedProduct, setFocusedProduct] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [billId, setBillId] = useState<any>({});
  const [paymentMode, setPaymentMode] = useState<any>('U');
  const debouncedValue = useDebounce<string>(search, 200);
  const [editData, setEditData] = useState<any>(null);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({ content: () => componentRef?.current });

  useEffect(() => {
    const data = localStorage.getItem('editData');
    if (!!data) {
      const formatted = JSON.parse(data);
      setEditData(formatted);
      setBilledProducts(formatted.items);
      setPaymentMode(formatted.bill.payment_mode);
      localStorage.removeItem('editData');
    }
  }, []);

  useEffect(() => {
    if (!orderNo) {
      fetchOrderNo();
    }
  }, [orderNo]);

  const fetchOrderNo = async () => {
    const order = await fetchOrderNo_db();
    dispatch(setOrderNo(order));
  };

  useEffect(() => {
    if (!!billId?.bill?.order_no && (billId?.bill?.order_no === orderNo || !!editData)) {
      handlePrint();
      setEditData(null);
    }
  }, [billId]);

  useKeyboardShortcut(() => handleSubmit(), {
    code: 'KeyS',
    ctrlKey: true,
  });

  useKeyboardShortcut(() => focusSearch(), {
    code: 'KeyF',
    ctrlKey: true,
  });

  const totalBillValue = useMemo(() => billedProducts.reduce((a, b) => a + b.amount, 0), [billedProducts]);

  const products = useMemo(() => {
    if (!!debouncedValue) {
      const deSearch = debouncedValue.toLowerCase();
      let filtered = formattedProducts.filter(
        (i: any) => i.code.startsWith(deSearch) || i.nameLower.startsWith(deSearch),
      );
      if (filtered.length === 0) {
        filtered = formattedProducts.filter((i: any) => i.code.includes(deSearch) || i.nameLower.includes(deSearch));
      }
      setFocusedProduct(filtered.length > 0 ? filtered[0] : null);
      return filtered;
    } else {
      !!focusedProduct && setFocusedProduct(null);
      return formattedProducts;
    }
  }, [formattedProducts, debouncedValue]);

  const setFocus = (code: string) => () => {
    let cell = document.getElementById(`input_${code}`);
    cell?.focus();
  };

  const addBilledProducts = (product: any) => {
    const existing = billedProducts.find((i: any) => i.code === product.code);
    if (!existing) {
      setBilledProducts([...billedProducts, calculateItemCost(product, 1)]);
    }
    window.requestAnimationFrame(setFocus(product.code));
  };

  const handleFocus = (event: any) => event?.target?.select();

  const calculateItemCost = (product: any, qty: number): any => {
    return {
      ...product,
      qty: qty,
      amount: qty * product.rate,
      total_cost: qty * product.cost,
      total_tax: qty * product.tax_value,
    };
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      focusSearch();
    }
  };

  const focusSearch = () => {
    let search = document.getElementById(`search_input`);
    search?.focus();
  };

  const handleSearchKeyDown = (event: any) => {
    if (event.key === 'Enter' && !!focusedProduct) {
      addBilledProducts(focusedProduct);
    }
  };

  const onChangeQty = (position: number, event: any) => {
    event.preventDefault();
    let qty = event.target.value;
    if (qty === '' || re.test(qty)) {
      setBilledProducts([
        ...billedProducts.map((i: any, index) => (index === position ? calculateItemCost(i, qty) : i)),
      ]);
    }
  };

  const removeProduct = (position: number) => {
    setBilledProducts([...billedProducts.filter((i: any, index) => index !== position)]);
  };

  const handleSubmit = () => {
    const billed = billedProducts.filter(i => !!i.qty && i.qty !== '' && !!i.amount);
    if (billed.length === 0 || totalBillValue === 0) {
      return;
    }
    let currentTime = new Date().toISOString();
    let order_no = orderNo;
    if (!!editData) {
      currentTime = editData.bill.created_at;
      order_no = editData.bill.order_no;
    }
    const payload = {
      items: billed,
      total: {
        bid: !!editData ? editData.bill.bid : null,
        changed_by: id,
        created_at: currentTime,
        changed_at: currentTime,
        payment_mode: paymentMode,
        order_no: order_no,
        ...billed.reduce(
          (a, b) => ({
            qty: parseFloat(a.qty) + parseFloat(b.qty),
            amount: a.amount + b.amount,
            total_cost: a.total_cost + b.total_cost,
            total_tax: a.total_tax + b.total_tax,
          }),
          {
            qty: 0,
            amount: 0,
            total_cost: 0,
            total_tax: 0,
          },
        ),
      },
    };
    setBillId({ bill: payload.total, items: payload.items });
    setIsLoading(true);
    setTimeout(() => {
      callMutate(payload);
    }, 50);
  };

  const callMutate = (payload: any) => {
    saveBill.mutate(payload, {
      onSuccess: (response: any) => {
        queryClient.invalidateQueries({ queryKey: [apiRoutes.BILLS_LIST], refetchActive: false });
        setEditData(null);
        setBilledProducts([]);
        setPaymentMode('U');
        setSearch('');
        setIsLoading(false);
        focusSearch();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setEditData(null);
          setBilledProducts([]);
          setPaymentMode('U');
          setSearch('');
          setIsLoading(false);
        }
      },
    });
  };

  return (
    <FormStyles.Container>
      <FormStyles.ViewContainer>
        <TextField
          placeholder="Search"
          name="searchItm"
          autoFocus
          onFocus={handleFocus}
          onKeyDown={handleSearchKeyDown}
          autoComplete="off"
          InputProps={{
            id: `search_input`,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
        />
        <FormStyles.ViewGroup>
          <FormStyles.GroupLabel>Category</FormStyles.GroupLabel>
          <Grid item container alignItems="left" gap={1}>
            {categoryList.map((i: any) => (
              <Grid key={i.cat_id} item alignItems="left" gap={1}>
                <MainCard>
                  <ButtonBase onClick={() => setSearch(i.code)}>
                    <FormStyles.Category>
                      <span className="pImage">
                        <img src={`/assets/images/category/${i.img}.jpg`} alt={i.name} />
                      </span>
                      <span className="pName">{i.name}</span>
                      <span className="pCode">{i.code}</span>
                    </FormStyles.Category>
                  </ButtonBase>
                </MainCard>
              </Grid>
            ))}
          </Grid>
        </FormStyles.ViewGroup>
        <FormStyles.ViewGroup>
          <FormStyles.GroupLabel>All Items</FormStyles.GroupLabel>
          <Grid item container alignItems="left" gap={1}>
            {products.map((i: any) => (
              <Grid key={i.pid} item alignItems="left" gap={1}>
                <MainCard className={focusedProduct?.pid === i.pid ? 'focused' : ''}>
                  <ButtonBase onClick={() => addBilledProducts(i)}>
                    <FormStyles.Category className="product">
                      <span className="pName">{i.name}</span>
                      <span className="pCode">{i.codeLabel}</span>
                    </FormStyles.Category>
                  </ButtonBase>
                </MainCard>
              </Grid>
            ))}
          </Grid>
        </FormStyles.ViewGroup>
      </FormStyles.ViewContainer>
      <FormStyles.FormContainer>
        <MainCard sx={{ height: '100%' }}>
          <FormStyles.FormSection>
            <FormStyles.GroupLabel>Order #{!!editData ? editData.bill.order_no : orderNo}</FormStyles.GroupLabel>
            {billedProducts.map((i, index) => (
              <FormStyles.BillItemSection key={i.code}>
                <FormStyles.BillItemName>
                  <span>{i.name}</span>
                  <IconButton aria-label="delete" onClick={() => removeProduct(index)}>
                    <CloseIcon />
                  </IconButton>
                </FormStyles.BillItemName>
                <FormStyles.BillItemAction>
                  <FormStyles.ItemQty>
                    <TextField
                      inputProps={{ id: `input_${i.code}` }}
                      onFocus={handleFocus}
                      placeholder="Search"
                      name="searchItm"
                      autoComplete="off"
                      onKeyDown={handleKeyDown}
                      value={i.qty}
                      onChange={(e: any) => onChangeQty(index, e)}
                    />
                  </FormStyles.ItemQty>
                  <span>x</span>
                  <span>{i.rate}</span>
                  <span>=</span>
                  <span className="amount">{i.amount}</span>
                </FormStyles.BillItemAction>
              </FormStyles.BillItemSection>
            ))}
          </FormStyles.FormSection>
          <FormStyles.FormFooter>
            <FormStyles.PaymentModes>
              <Button
                aria-label="delete"
                fullWidth
                color="primary"
                variant={paymentMode === 'C' ? 'contained' : 'outlined'}
                onClick={() => setPaymentMode('C')}
              >
                <RupeeIcon />
              </Button>
              <Button
                aria-label="delete"
                fullWidth
                color="primary"
                variant={paymentMode === 'U' ? 'contained' : 'outlined'}
                onClick={() => setPaymentMode('U')}
              >
                <UpiICon />
              </Button>
            </FormStyles.PaymentModes>
            <FormStyles.TotalPriceInfo>
              <span>Total</span>
              <span className="amount">{currencyFormat(totalBillValue)}</span>
            </FormStyles.TotalPriceInfo>
            {!isLoading ? (
              <Button fullWidth variant="contained" onClick={() => handleSubmit()} disabled={totalBillValue === 0}>
                Save
              </Button>
            ) : (
              <Button fullWidth variant="contained" onClick={() => {}} disabled={true}>
                <CircularProgress size={24} />
              </Button>
            )}
          </FormStyles.FormFooter>
        </MainCard>
      </FormStyles.FormContainer>
      <BillPrint ref={componentRef} billDetails={billId} />
    </FormStyles.Container>
  );
};

export default BillForm;
