import { IOrderByDirections, ITablePayload } from 'services/types';

export const productsTableConfig = [
  {
    id: 'sno',
    align: 'left',
    label: 'S.No',
    hasSort: false,
    customCell: true,
  },
  {
    id: 'name',
    align: 'left',
    label: 'Name',
    hasSort: true,
  },
  {
    id: 'code',
    align: 'left',
    label: 'Code',
    hasSort: true,
  },
  {
    id: 'category',
    align: 'left',
    label: 'Category',
    hasSort: true,
    customCell: true,
  },
  // {
  //   id: 'sub_category',
  //   align: 'left',
  //   label: 'Sub Category',
  //   hasSort: true,
  // },
  // {
  //   id: 'hsn',
  //   align: 'left',
  //   label: 'HSN',
  //   hasSort: false,
  // },
  {
    id: 'rate',
    align: 'left',
    label: 'Rate',
    hasSort: false,
  },
  {
    id: 'action',
    align: 'right',
    label: '',
    hasSort: false,
    customCell: true,
  },
];

export const productsTablePayload: ITablePayload = {
  fields: ['pid', 'name', 'code', 'hsn', 'gst', 'cat_id', 'rate'],
  conditions: [],
  orderBy: { field: 'changed_at', direction: IOrderByDirections.DESC },
  pagination: {
    skip: 0,
    take: 20,
  },
};

export const allProductsPayload: ITablePayload = {
  fields: ['pid', 'name', 'code', 'cat_id', 'hsn', 'gst', 'cost', 'rate', 'tax_value'],
  conditions: [],
  orderBy: { field: 'code', direction: IOrderByDirections.ASC },
};
