import { Box } from '@mui/material';
import ModalBoxstyles from './styles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px',
  boxShadow: 24,
};

const ModalBox = ({ children }: any) => {
  return (
    <ModalBoxstyles.BackDrop>
      <Box sx={{ ...style, width: '800px' }}>{children}</Box>
    </ModalBoxstyles.BackDrop>
  );
};

export default ModalBox;
