import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export type BillsState = {
  orderNo: number;
};

export const initialState: BillsState = {
  orderNo: 0,
};
// A slice
const billSlice = createSlice({
  name: 'bills',
  initialState,
  reducers: {
    setOrderNo: (state, action: PayloadAction<any>) => {
      return { ...state, orderNo: action.payload };
    },
  },
});

// Actions generated from the slice
export const { setOrderNo } = billSlice.actions;

// export user selector to get the slice in any component
export const billsSelector = (state: RootState) => state.bills;
// export The reducer
const billsReducer = billSlice.reducer;
export default billsReducer;
