import styled from 'styled-components';

const AppLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  background-color: rgb(30, 30, 30);
  align-items: center;
  .progress {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    display: flex;
  }
  .progress .color {
    background-color: crimson;
    width: 75px;
    height: 75px;
    padding: 0px;
    position: absolute;
    margin: 0 auto;
    top: 0;
    border-radius: 50%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    animation: bouncing 2s ease-in-out infinite;
    img {
      width: 100%;
    }
  }
  .textInfo {
    position: absolute;
    bottom: -100px;
    font-weight: 600;
    color: #fff;
    font-size: 22px;
  }
  @keyframes bouncing {
    0% {
      top: 10%;
      transform: rotate(0deg);
    }
    30% {
      top: 80%;
    }
    60% {
      top: 0%;
    }
    90% {
      top: 10%;
    }
    100% {
      transform: rotate(360deg);
      top: 10%;
    }
  }
`;

const styles = { AppLoaderContainer };

export default styles;
