import { IOrderByDirections, ITablePayload } from 'services/types';

export const purchaseTableConfig = [
  {
    id: 'sno',
    align: 'left',
    label: 'S.No',
    hasSort: false,
    customCell: true,
  },
  {
    id: 'date',
    align: 'left',
    label: 'Bill Date',
    hasSort: true,
    customCell: true,
  },
  {
    id: 'supplier',
    align: 'left',
    label: 'Supplier',
    customCell: true,
  },
  {
    id: 'bill_no',
    align: 'left',
    label: 'Bill No',
    hasSort: true,
  },
  {
    id: 'payable_amount',
    align: 'right',
    label: 'Amount',
    hasSort: true,
  },
  {
    id: 'created_at',
    align: 'left',
    label: 'Added On',
    customCell: true,
  },
  {
    id: 'action',
    align: 'right',
    label: '',
    hasSort: false,
    customCell: true,
  },
];

export const purchaseTablePayload: ITablePayload = {
  fields: ['pu_id', 'date', 'sid', 'bill_no', 'payable_amount', 'created_at'],
  conditions: [],
  orderBy: { field: 'created_at', direction: IOrderByDirections.DESC },
  pagination: {
    skip: 0,
    take: 20,
  },
};
