// project import
import { Box } from '@mui/material';
import Navigation from './Navigation';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (
  <Box
    sx={{
      overflowX: 'auto',
      '& .simplebar-content': {
        display: 'flex',
        flexDirection: 'column',
      },
    }}
  >
    <Navigation />
  </Box>
);

export default DrawerContent;
