import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import GlobalStateComponent from 'components/GlobalStateComponent';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Navigation from './routes';
import store from './store';
import ThemeCustomization from './themes';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      staleTime: Infinity,
    },
  },
});
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeCustomization>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <Navigation />
              <GlobalStateComponent />
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeCustomization>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
