import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Button from '@mui/material/Button';
import MainCard from 'components/MainCard';
import { appMinDate } from 'configs/style.config';
import { addDays, addWeeks, format, isToday, isTomorrow, isYesterday, startOfWeek, subWeeks } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { NoData } from '../../components/index-table/NoData';
import Styles from '../../components/index-table/styles';
import AttendanceStyles from './styles';

const defaultColumns: any[] = [
  {
    id: 'employee',
    align: 'left',
    label: 'Employee',
    hasSort: false,
    customCell: true,
  },
];

const Attendance = () => {
  const results: any[] = [];
  const isFetching = false;
  const [startDate, setStartDate] = useState(startOfWeek(new Date()));

  const columns = useMemo(() => {
    return [
      ...defaultColumns,
      ...[0, 1, 2, 3, 4, 5, 6].map(i => ({
        id: `date_${i}`,
        align: 'center',
        label: '',
        hasSort: false,
        customCell: true,
        dateCell: true,
        position: i,
      })),
    ];
  }, [startDate]);

  const getCellHeader = (col: any) => {
    const current = addDays(startDate, col.position);
    return (
      <AttendanceStyles.DateCellHeader>
        <span className="day">{format(current, 'EEEE')}</span>
        <span className="date">{format(addDays(startDate, col.position), 'dd-MMM-yyyy')}</span>
        <span className="dateType">
          <span className="today">{isToday(current) ? 'Today' : ''}</span>
          {isYesterday(current) ? 'Yesterday' : ''}
          {isTomorrow(current) ? 'Tomorrow' : ''} &nbsp;
        </span>
      </AttendanceStyles.DateCellHeader>
    );
  };

  const navigateNext = () => {
    const newVal = addWeeks(startDate, 1);
    if (newVal > new Date()) {
      return;
    }
    setStartDate(newVal);
  };
  const navigateBefore = () => {
    const newVal = subWeeks(startDate, 1);
    if (newVal < appMinDate) {
      return;
    }
    setStartDate(newVal);
  };
  return (
    <AttendanceStyles.Container>
      <AttendanceStyles.CalenderContainer>
        <AttendanceStyles.Actions>
          <Button variant="outlined" startIcon={<NavigateBeforeIcon />} onClick={navigateBefore}></Button>
          <Button variant="outlined" startIcon={<NavigateNextIcon />} onClick={navigateNext}></Button>
          <Button variant="contained" size="small">
            Add New Employee
          </Button>
        </AttendanceStyles.Actions>
        <MainCard>
          <AttendanceStyles.TableContainer>
            <TableContainer
              sx={{
                height: 'calc(100vh - 150px)',
                width: '100%',
                overflowX: 'auto',
                position: 'relative',
                display: 'block',
                maxWidth: '100%',
                '& td, & th': { whiteSpace: 'nowrap' },
              }}
            >
              <Table
                className="indexTable"
                stickyHeader
                size="small"
                aria-labelledby="tableTitle"
                sx={{
                  '& .th_col_0': {
                    pl: 3,
                  },
                  '& .MuiTableCell-root:last-child': {
                    pr: 3,
                  },
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((headCell: any, columnIndex: number) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={'none'}
                        className={`th_col_${columnIndex}`}
                      >
                        {headCell.hasSort ? (
                          <TableSortLabel onClick={() => null}>{headCell.label}</TableSortLabel>
                        ) : (
                          <>{headCell.dateCell ? <>{getCellHeader(headCell)}</> : <>{headCell.label}</>}</>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.map((row: any, index: number) => (
                    <TableRow
                      hover
                      role="checkbox"
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      aria-checked={false}
                      tabIndex={-1}
                      key={index}
                      selected={false}
                    >
                      {columns.map((headCell: any, columnIndex: number) => (
                        <React.Fragment key={headCell.id}>
                          <TableCell className={`th_col_${columnIndex}`} align={headCell.align} padding={'none'}>
                            {row[headCell.id]}
                          </TableCell>
                        </React.Fragment>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {!isFetching && results.length === 0 && (
                <Styles.NoDataContainer>
                  <NoData />
                  <span>No results found</span>
                </Styles.NoDataContainer>
              )}
              <Backdrop
                sx={{ color: '#fff', position: 'absolute', zIndex: theme => theme.zIndex.drawer + 1 }}
                open={isFetching}
              >
                <CircularProgress color="primary" />
              </Backdrop>
            </TableContainer>
          </AttendanceStyles.TableContainer>
        </MainCard>
      </AttendanceStyles.CalenderContainer>
    </AttendanceStyles.Container>
  );
};

export default Attendance;
