export const apiRoutes = {
  USER_INFO: 'auth/user-info',
  PRODUCT_LIST: 'products/list',
  PURCHASE_LIST: 'purchase/list',
  PURCHASE: 'purchase',
  PAYMENTS_LIST: 'payments/list',
  PAYMENTS: 'payments',
  PRODUCTS: 'products',
  BILLS: 'bills',
  BILLS_LIST: 'bills/list',
  BILLS_ORDER_NO: 'bills/order-no',
  CATEGORY_LIST: 'categories/list',
  LOGIN: 'auth/login',
  REPORT: 'report',
  SUPPLIER_LIST: 'suppliers/list',
  SUPPLIERS: 'suppliers',
};
export enum STATUS_CODES {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  INTERNAL_SERVER_ERROR = 500,
  NOT_FOUND = 404,
  UNPROCESSABLE_ENTITY = 422,
}
