import globalConstants from 'constants/global.constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { authSelector } from '../store/auth';

const PublicRoutes = () => {
  const { token: storeToken } = useSelector(authSelector);
  const token = useMemo(() => localStorage.getItem(globalConstants.TOKEN_STORAGE_KEY), [storeToken]);

  if (!token) {
    return <Outlet />;
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default PublicRoutes;
