import { useMutation, useQuery } from 'react-query';
import { apiRoutes } from '../constants/api.constant';
import apiClient from './api.service';

export const useGetUserInfo = (config = { enabled: true }) => {
  return useQuery([apiRoutes.USER_INFO], userInfo, { ...config });
};

export const usePostLogin = (): any => useMutation(postLogin);

const userInfo = async () => await apiClient.get(apiRoutes.USER_INFO);
const postLogin = async (data: ILoginForm) => await apiClient.post(apiRoutes.LOGIN, data);

export interface ILoginForm {
  email: FormDataEntryValue | null;
  password: FormDataEntryValue | null;
}
