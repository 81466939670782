import AddIcon from '@mui/icons-material/Add';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Button, Drawer, Grid, IconButton, TableCell, TextField, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MainCard from 'components/MainCard';
import IndexTable from 'components/index-table';
import globalConstants from 'constants/global.constants';
import useDebounce from 'hooks/useDebounce';
import { useGetFormattedProducts } from 'pages/bills-form/helpers';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePurchaseTableData } from 'services/purchase.service';
import { authSelector } from 'store/auth';
import { timeFormat } from 'utils/dateFormat';
import { purchaseTableConfig, purchaseTablePayload } from './config';
import PurchaseForm from './purchase-form';

const defaultFormData = {
  formType: globalConstants.FORM_TYPE.CREATE,
  isOpen: false,
  formId: null,
};

const Purchase = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const { permissions } = useSelector(authSelector);

  const [payload, setPayload] = useState(purchaseTablePayload);
  const { data, isFetching } = usePurchaseTableData(payload);

  const { supplierMap } = useGetFormattedProducts();
  const [formConfig, setFormConfig] = useState(defaultFormData);

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (debouncedValue) {
      setPayload({
        ...payload,
        conditions: [
          ...payload.conditions.filter(p => p.field !== 'payable_amount'),
          { field: 'payable_amount', condition: 'LIKE', value: `%${debouncedValue}%` },
        ],
      });
    } else {
      setPayload({ ...payload, conditions: payload.conditions.filter(p => p.field !== 'payable_amount') });
    }
  }, [debouncedValue]);

  const getCustomCell = (column: any, row: any, rowIndex: number, columnIndex: number) => {
    if (column.id === 'sno') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {(purchaseTablePayload?.pagination?.skip || 0) + rowIndex + 1}
        </TableCell>
      );
    }
    if (column.id === 'supplier') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {supplierMap[row.sid]?.name}
        </TableCell>
      );
    }
    if (column.id === 'created_at' || column.id === 'date') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {timeFormat(row[column.id])}
        </TableCell>
      );
    }
    return (
      <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
        {permissions.addPurchase && (
          <IconButton aria-label="delete" color="primary" onClick={() => handleOnClick(row)}>
            <SaveAsIcon />
          </IconButton>
        )}
      </TableCell>
    );
  };
  const handleOnClick = (row: any) => {
    setFormConfig({ formType: globalConstants.FORM_TYPE.UPDATE, isOpen: true, formId: row.pu_id });
  };
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ pb: 2, gap: 1 }}>
        <MuiBreadcrumbs aria-label="breadcrumb">
          <Typography color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
            Master
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            Purchase
          </Typography>
        </MuiBreadcrumbs>
        <Grid item>
          <Grid item container alignItems="center" gap={1}>
            <TextField
              autoFocus
              id="outlined-basic"
              placeholder="Search"
              value={searchTerm}
              variant="outlined"
              onChange={handleChange}
            />
            {permissions.addPurchase && (
              <Button
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => setFormConfig({ ...defaultFormData, isOpen: true })}
              >
                Purchase Entry
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <MainCard>
        <IndexTable
          isFetching={isFetching}
          columns={purchaseTableConfig}
          data={data}
          payload={payload}
          getCustomCell={getCustomCell}
          onPayloadChange={(newPayload: any) => setPayload(newPayload)}
          clearFilters={() => {
            setSearchTerm('');
            setPayload({ ...payload, conditions: [] });
          }}
          onRowClick={(row: any) => handleOnClick(row)}
        />
      </MainCard>
      <Drawer
        variant="temporary"
        open={formConfig.isOpen}
        ModalProps={{ keepMounted: true }}
        anchor="right"
        onClose={() => setFormConfig(defaultFormData)}
      >
        {formConfig.isOpen && <PurchaseForm onClose={() => setFormConfig(defaultFormData)} formConfig={formConfig} />}
      </Drawer>
    </>
  );
};

export default Purchase;
