import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import MainCard from 'components/MainCard';
import ModalBoxstyles from 'components/modal-box/styles';
import { STATUS_CODES, apiRoutes } from 'constants/api.constant';
import globalConstants from 'constants/global.constants';
import { startOfDay } from 'date-fns';
import dayjs from 'dayjs';
import { allSuppliersPayload } from 'pages/suppliers/config';
import SupplierInput from 'pages/suppliers/supplier-input';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useDeletePurchase, usePurchaseDetail, useSavePurchase, useUpdatePurchase } from 'services/purchase.service';
import { useSupplierTableData } from 'services/supplier.service';
import { showToast } from 'store/global';

interface IPurchaseForm {
  formConfig: any;
  onClose: Function;
}

const DEFAULT_FORM_DATA = {
  sid: '',
  date: startOfDay(new Date()).toISOString(),
  payable_amount: 0,
  bill_no: '',
  notes: '',
};
const PurchaseForm = ({ onClose, formConfig }: IPurchaseForm) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>(DEFAULT_FORM_DATA);
  const [formError, setFormError] = useState<any>({});
  const supplierList = useSupplierTableData(allSuppliersPayload);
  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);

  const inputRef: any = useRef(null);
  const { data: productDetailResponse, isFetching } = usePurchaseDetail(formConfig.formId);

  const savePurchase = useSavePurchase();
  const updatePurchase = useUpdatePurchase();
  const deletePurchase = useDeletePurchase();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, [inputRef]);

  useEffect(() => {
    if (productDetailResponse) {
      setFormData({ ...DEFAULT_FORM_DATA, ...productDetailResponse });
    }
    return () => {
      setFormData({ ...DEFAULT_FORM_DATA });
    };
  }, [productDetailResponse]);

  const onDateChange = (dVal: dayjs.Dayjs | null, key: string) => {
    setFormData({ ...formData, [key]: dVal?.toISOString() });
  };

  const setInputValue = (event: any, field: string) => {
    event.preventDefault();
    const value = event.target.value;
    setFormData({ ...formData, [field]: value });
    setFormError({ ...formError, [field]: '' });
  };

  const setNumberInputValue = (event: any, field: string) => {
    event.preventDefault();
    const value = event.target.value;
    const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (value === '' || re.test(value)) {
      setFormData({ ...formData, [field]: value });
    }
    setFormError({ ...formError, [field]: '' });
  };

  const onSave = () => {
    savePurchase.mutate(formData, {
      onSuccess: response => {
        dispatch(showToast({ type: 'success', message: 'Purchase created' }));
        queryClient.invalidateQueries({ queryKey: [apiRoutes.PURCHASE_LIST], refetchActive: true });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  const onUpdate = () => {
    updatePurchase.mutate(formData, {
      onSuccess: () => {
        dispatch(showToast({ type: 'success', message: 'Purchase details updated' }));
        queryClient.invalidateQueries({ queryKey: [apiRoutes.PURCHASE_LIST], refetchActive: true });
        queryClient.invalidateQueries({ queryKey: [apiRoutes.SUPPLIER_LIST], refetchActive: true });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  const onDelete = () => {
    toggleDeleteConfirmation(false);
    deletePurchase.mutate(formData.pu_id, {
      onSuccess: () => {
        dispatch(showToast({ type: 'warning', message: 'Purchase details deleted' }));
        queryClient.invalidateQueries({
          queryKey: [apiRoutes.PURCHASE_LIST, 'usePurchaseTableData'],
          refetchActive: true,
        });
        queryClient.invalidateQueries({ queryKey: [apiRoutes.SUPPLIER_LIST], refetchActive: true });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  return (
    <>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={
            savePurchase.isLoading ||
            updatePurchase.isLoading ||
            deletePurchase.isLoading ||
            isFetching ||
            supplierList.isFetching
          }
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <MainCard
          sx={{ width: 500 }}
          title={formConfig.formType === globalConstants.FORM_TYPE.CREATE ? 'Purchase Entry' : 'Update Purchase'}
          secondary={
            formConfig.formType === globalConstants.FORM_TYPE.CREATE ? (
              <ModalBoxstyles.ActionContainer>
                <Button variant="contained" size="small" startIcon={<SaveIcon />} onClick={onSave}>
                  Save
                </Button>
                <Button color="secondary" size="small" startIcon={<CancelIcon />} onClick={() => onClose()}>
                  Cancel
                </Button>
              </ModalBoxstyles.ActionContainer>
            ) : (
              <ModalBoxstyles.ActionContainer>
                <Button color="warning" variant="contained" size="small" startIcon={<UpdateIcon />} onClick={onUpdate}>
                  Update
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => toggleDeleteConfirmation(true)}
                >
                  Delete
                </Button>
                <Button color="secondary" size="small" startIcon={<CancelIcon />} onClick={() => onClose()}>
                  Cancel
                </Button>
              </ModalBoxstyles.ActionContainer>
            )
          }
        >
          <Grid item xs={6} md={12} sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', gap: 2 }}>
              <SupplierInput
                value={formData.sid}
                onChange={(event: any) => setInputValue(event, 'sid')}
                error={formError.sid?.length > 0}
                helperText={formError.sid}
              />
              <DatePicker
                label="Date"
                disableFuture
                disableMaskedInput
                inputFormat="DD MMM, YYYY"
                value={dayjs(formData.date)}
                onChange={(v: any) => onDateChange(v, 'date')}
                renderInput={params => <TextField aria-readonly {...params} />}
              />
              <TextField
                label="Bill No"
                value={formData.bill_no}
                autoComplete="off"
                error={formError.bill_no?.length > 0}
                helperText={formError.bill_no}
                onChange={event => setInputValue(event, 'bill_no')}
              />
              <TextField
                label="Payable Amount"
                value={formData.payable_amount}
                autoComplete="off"
                error={formError.payable_amount?.length > 0}
                helperText={formError.payable_amount}
                onChange={event => setNumberInputValue(event, 'payable_amount')}
              />
              <TextField
                label="Notes"
                value={formData.notes}
                autoComplete="off"
                error={formError.notes?.length > 0}
                helperText={formError.notes}
                onChange={event => setInputValue(event, 'notes')}
              />
            </Box>
          </Grid>
        </MainCard>
      </div>
      <Dialog open={showDeleteConfirmation} onClose={() => toggleDeleteConfirmation(false)}>
        <DialogTitle>{'Are you sure to Delete?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Once delete you can't restore data!.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={onDelete} autoFocus color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PurchaseForm;
