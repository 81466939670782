import styled, { createGlobalStyle } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
`;
const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  height: auto;
  overflow: auto;
  padding-right: 20px;
`;
const ViewGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .focused {
    border: 2px solid ${props => props.theme.palette.primary.light};
  }
`;
const GroupLabel = styled.div`
  font-weight: 500;
  font-size: 18px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  min-width: 350px;
  .MuiCardContent-root {
    height: 100%;
    overflow: auto;
  }
`;
const Summary = styled.div`
  display: flex;
  width: 25%;
  min-width: 350px;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;
const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 10px;
  height: calc(100% - 164px);
  overflow: auto;
`;

const Category = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;
  gap: 5px;
  &.product {
    min-width: 150px;
  }
  .pImage {
    width: 120px;
    height: 120px;
    border-radius: 6px;
    overflow: hidden;
    padding-bottom: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
  .pName {
    font-size: 16px;
  }
  .pCode {
    font-size: 12px;
  }
`;

const BillItemSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  border: 1px solid;
  border-radius: 8px;
  border-color: #e6ebf1;
`;

const BillItemName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
`;

const BillItemAction = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  .amount {
    font-size: 18px;
    font-weight: 800;
  }
`;

const ItemQty = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 30%;
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  position: sticky;
  bottom: 0;
  background-color: ${props => props.theme.palette.background.paper};
  border-top: 1px solid ${props => props.theme.palette.divider};
`;

const TotalPriceInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
`;

const PaymentModes = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const FormStyles = {
  Summary,
  Container,
  ViewContainer,
  FormContainer,
  ViewGroup,
  GroupLabel,
  FormSection,
  Category,
  BillItemSection,
  BillItemName,
  BillItemAction,
  ItemQty,
  FormFooter,
  TotalPriceInfo,
  PaymentModes,
};

export default FormStyles;

export const GlobalStyle = createGlobalStyle`
  @media print {
    body {
      // visibility: hidden;
    }
    #section-to-print {
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
`;
