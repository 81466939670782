// material-ui
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
} from '@mui/material';
import React from 'react';
import { NoData } from './NoData';
import { NotFound } from './NotFound';
import Styles from './styles';

export interface IIndexTableProps {
  data: any;
  columns: any[];
  payload?: any;
  getCustomCell?: Function;
  isFetching?: boolean;
  onPayloadChange: Function;
  hasPagination?: boolean;
  clearFilters: Function;
  tableHeader?: React.ReactElement;
  tableFooter?: React.ReactElement;
  tableId?: string;
  onRowClick?: Function;
}

function IndexTable({
  columns,
  data,
  payload,
  getCustomCell,
  isFetching = false,
  hasPagination = true,
  onPayloadChange,
  clearFilters,
  tableHeader,
  tableFooter,
  tableId,
  onRowClick,
}: IIndexTableProps) {
  const { results = [], totalCount = 0, skip = 0, take = 0 } = data || {};
  const theme = useTheme();
  return (
    <Box>
      <Styles.IndexTableStyles />
      <TableContainer
        sx={{
          height: 'calc(100vh - 215px)',
          width: '100%',
          overflowX: 'auto',
          position: 'relative',
          display: 'block',
          maxWidth: '100%',
          '& td, & th': { whiteSpace: 'nowrap' },
        }}
      >
        <Table
          className="indexTable"
          id={tableId}
          stickyHeader
          size="small"
          aria-labelledby="tableTitle"
          sx={{
            '& .th_col_0': {
              pl: 3,
            },
            '& .MuiTableCell-root:last-child': {
              pr: 3,
            },
            '& .MuiTableRow-root:nth-of-type(odd)': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((headCell: any, columnIndex: number) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.align}
                  padding={'none'}
                  className={`th_col_${columnIndex}`}
                >
                  {headCell.hasSort ? (
                    <TableSortLabel onClick={() => null}>{headCell.label}</TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!tableHeader ? tableHeader : <></>}
            {results.map((row: any, index: number) => (
              <TableRow
                hover
                className={!!onRowClick ? 'tableRow' : ''}
                role="checkbox"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                aria-checked={false}
                tabIndex={-1}
                key={index}
                selected={false}
                onClick={() => (!!onRowClick ? onRowClick(row, index) : null)}
              >
                {columns.map((headCell: any, columnIndex: number) => (
                  <React.Fragment key={headCell.id}>
                    {headCell.customCell ? (
                      <>{getCustomCell && getCustomCell(headCell, row, index, columnIndex)}</>
                    ) : (
                      <>
                        <TableCell className={`th_col_${columnIndex}`} align={headCell.align} padding={'none'}>
                          {row[headCell.id]}
                        </TableCell>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
            ))}

            {!!tableFooter ? tableFooter : <></>}
          </TableBody>
        </Table>
        {!isFetching && totalCount === 0 && results.length === 0 && payload.conditions.length === 0 && (
          <Styles.NoDataContainer>
            <NoData />
            <span>No results found</span>
          </Styles.NoDataContainer>
        )}
        {!isFetching && totalCount === 0 && payload.conditions.length > 0 && (
          <Styles.NoDataContainer>
            <NotFound />
            <Button color="warning" size="small" onClick={() => clearFilters()}>
              Clear search & filters
            </Button>
          </Styles.NoDataContainer>
        )}
        <Backdrop
          sx={{ color: '#fff', position: 'absolute', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={isFetching}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      </TableContainer>
      {hasPagination && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 20, 50]}
          colSpan={3}
          count={totalCount}
          rowsPerPage={take}
          page={skip / take || 0}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={(e, page) =>
            onPayloadChange({ ...payload, pagination: { ...payload.pagination, skip: page * payload.pagination.take } })
          }
          onRowsPerPageChange={e =>
            onPayloadChange({ ...payload, pagination: { ...payload.pagination, take: e.target.value } })
          }
        />
      )}
    </Box>
  );
}

export default IndexTable;
