import Logo from 'pages/landing/Drawer/DrawerHeader/Logo';
import { forwardRef } from 'react';
import { dateFormat } from 'utils/dateFormat';
import { currencyFormat, numberFormat } from 'utils/numberFormat';
import BillStyles from './billStyles';

const BillPrint = forwardRef(({ billDetails }: { billDetails: any }, ref: any) => {
  // const gstInfo = useMemo(() => {
  //   let gstGroup: any = {};
  //   billDetails.items.forEach((item: any) => {
  //     if (gstGroup.hasOwnProperty(item.gst)) {
  //       const existing = gstGroup[item.gst];
  //       gstGroup[item.gst] = {
  //         total_cost: existing.total_cost + item.total_cost,
  //         total_tax: existing.total_tax + item.total_tax,
  //       };
  //     } else {
  //       gstGroup[item.gst] = { total_cost: item.total_cost, total_tax: item.total_tax };
  //     }
  //   });
  //   return Object.keys(gstGroup).map(element => {
  //     return { ...gstGroup[element], gst: element };
  //   });
  // }, [billDetails.items]);

  return (
    <BillStyles.PrintView>
      <BillStyles.Container ref={ref}>
        {!!billDetails.bill?.amount && (
          <>
            <BillStyles.Header>
              <Logo />
              <br />
              No:488-A, V.E.Road, Thoothukudi
              <br />
            </BillStyles.Header>
            <BillStyles.Divider />
            <BillStyles.DateInfo>
              <span>Bill No : {billDetails?.bill?.order_no}</span>
              <span>
                Date : {dateFormat(billDetails?.bill?.created_at, 'dd/MM/yyyy')}
                <br />
                Time : {dateFormat(billDetails?.bill?.created_at, 'hh:mm a')}
              </span>
            </BillStyles.DateInfo>
            <BillStyles.Divider />
            <table>
              <thead>
                <tr>
                  <th className="left"></th>
                  <th className="left">Item Name</th>
                  <th className="right">Qty</th>
                  <th className="right">Rate</th>
                  <th className="right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {billDetails.items.map((i: any, index: number) => (
                  <tr key={`${i.code}_${index}`}>
                    <td className="left">{index + 1}</td>
                    <td className="left">{i.name}</td>
                    <td className="right">{numberFormat(i.qty, { decimal: true })}</td>
                    <td className="right">{numberFormat(i.rate, { decimal: true })}</td>
                    <td className="right">{numberFormat(i.amount, { decimal: true })}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <BillStyles.Divider />
            <BillStyles.DateInfo>
              <span>Items : {billDetails.items.length}</span>
              <span>
                Total : <span className="total">{currencyFormat(billDetails.bill.amount, { decimal: true })}</span>
              </span>
            </BillStyles.DateInfo>

            <BillStyles.Divider />
            {/* <table>
              <thead>
                <tr>
                  <th className="left">GST%</th>
                  <th className="right">Sale Amt</th>
                  <th className="right">GST</th>
                  <th className="right">CGST</th>
                  <th className="right pad">SGST</th>
                </tr>
              </thead>
              <tbody>
                {gstInfo.map((i: any, index: number) => (
                  <tr key={i.gst}>
                    <td className="left">{numberFormat(i.gst, { decimal: true })}</td>
                    <td className="right">{numberFormat(i.total_cost, { decimal: true })}</td>
                    <td className="right">{numberFormat(i.total_tax, { decimal: true })}</td>
                    <th className="right">{numberFormat(i.total_tax / 2, { decimal: true })}</th>
                    <td className="right pad">{numberFormat(i.total_tax / 2, { decimal: true })}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <BillStyles.Divider /> */}
            <BillStyles.Header>Thank You! Visit Again!</BillStyles.Header>
            {/* 
            <BillStyles.Divider />
            <BillStyles.Header>PH: 78754242457</BillStyles.Header> */}
          </>
        )}
      </BillStyles.Container>
    </BillStyles.PrintView>
  );
});

export default BillPrint;
