import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalSelector, hideToast } from 'store/global';
import { useOnlineStatus } from 'utils/useOnlineStatus';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GlobalStateComponent = () => {
  const dispatch = useDispatch();
  const { toast } = useSelector(globalSelector);
  const isOnline = useOnlineStatus();
  // useSyncBills();

  const handleToastClose = () => {
    dispatch(hideToast());
  };
  return (
    <>
      <Snackbar
        open={toast.isOpen}
        autoHideDuration={3000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleToastClose} severity={toast.type} sx={{ width: '100%' }}>
          {toast.message}
        </Alert>
      </Snackbar>
      {!isOnline && (
        <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity={'error'} sx={{ width: '100%' }}>{`You are offline !!`}</Alert>
        </Snackbar>
      )}
    </>
  );
};

export default GlobalStateComponent;
