const globalConstants = {
  TOKEN_STORAGE_KEY: 'token',
  FORM_TYPE: {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
  },
  INPUT_TYPE: {
    TEXT: 'TEXT',
    NUMBER: 'NUMBER',
    DATE: 'DATE',
  },
  DEFAULT_STATE_CODE: '33',
  STATES: [
    {
      id: 1,
      name: 'Andaman and Nicobar Islands',
      code: '35',
    },
    {
      id: 2,
      name: 'Andhra Pradesh',
      code: '28',
    },
    {
      id: 3,
      name: 'Andhra Pradesh (New)',
      code: '37',
    },
    {
      id: 4,
      name: 'Arunachal Pradesh',
      code: '12',
    },
    {
      id: 5,
      name: 'Assam',
      code: '18',
    },
    {
      id: 6,
      name: 'Bihar',
      code: '10',
    },
    {
      id: 7,
      name: 'Chandigarh',
      code: '04',
    },
    {
      id: 8,
      name: 'Chattisgarh',
      code: '22',
    },
    {
      id: 9,
      name: 'Dadra and Nagar Haveli',
      code: '26',
    },
    {
      id: 10,
      name: 'Daman and Diu',
      code: '25',
    },
    {
      id: 11,
      name: 'Delhi',
      code: '07',
    },
    {
      id: 12,
      name: 'Goa',
      code: '30',
    },
    {
      id: 13,
      name: 'Gujarat',
      code: '24',
    },
    {
      id: 14,
      name: 'Haryana',
      code: '06',
    },
    {
      id: 15,
      name: 'Himachal Pradesh',
      code: '02',
    },
    {
      id: 16,
      name: 'Jammu and Kashmir',
      code: '01',
    },
    {
      id: 17,
      name: 'Jharkhand',
      code: '20',
    },
    {
      id: 18,
      name: 'Karnataka',
      code: '29',
    },
    {
      id: 19,
      name: 'Kerala',
      code: '32',
    },
    {
      id: 20,
      name: 'Lakshadweep Islands',
      code: '31',
    },
    {
      id: 21,
      name: 'Madhya Pradesh',
      code: '23',
    },
    {
      id: 22,
      name: 'Maharashtra',
      code: '27',
    },
    {
      id: 23,
      name: 'Manipur',
      code: '14',
    },
    {
      id: 24,
      name: 'Meghalaya',
      code: '17',
    },
    {
      id: 25,
      name: 'Mizoram',
      code: '15',
    },
    {
      id: 26,
      name: 'Nagaland',
      code: '13',
    },
    {
      id: 27,
      name: 'Odisha',
      code: '21',
    },
    {
      id: 28,
      name: 'Pondicherry',
      code: '34',
    },
    {
      id: 29,
      name: 'Punjab',
      code: '03',
    },
    {
      id: 30,
      name: 'Rajasthan',
      code: '08',
    },
    {
      id: 31,
      name: 'Sikkim',
      code: '11',
    },
    {
      id: 32,
      name: 'Tamil Nadu',
      code: '33',
    },
    {
      id: 33,
      name: 'Telangana',
      code: '36',
    },
    {
      id: 34,
      name: 'Tripura',
      code: '16',
    },
    {
      id: 35,
      name: 'Uttar Pradesh',
      code: '09',
    },
    {
      id: 36,
      name: 'Uttarakhand',
      code: '05',
    },
    {
      id: 37,
      name: 'West Bengal',
      code: '19',
    },
  ],
};
export enum UserRole {
  EMPTY = '',
  ADMIN = 'admin',
  USER = 'user',
}
export const userRoles = [UserRole.ADMIN, UserRole.USER];
export default globalConstants;
