import styled from 'styled-components';

const Label = styled.span`
  font-size: 14px;
  padding: 0px 10px 0px 20px;
`;
const Value = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const ReportStyles = { Label, Value };

export default ReportStyles;
