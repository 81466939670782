import { UserRole } from 'constants/global.constants';
import { appRoutes } from 'constants/routes.constant';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { authSelector } from '../store/auth';

const RoleBasedRoute = ({ allowedRoles, component }: { allowedRoles: UserRole[]; component: React.ReactElement }) => {
  const { role } = useSelector(authSelector);

  if (allowedRoles.includes(role)) {
    return <React.Fragment>{component}</React.Fragment>;
  } else {
    return <Navigate to={appRoutes.BILLS} />;
  }
};

export default RoleBasedRoute;
