import { IOrderByDirections, ITablePayload } from 'services/types';

export const billsTableConfig = [
  {
    id: 'sno',
    align: 'left',
    label: 'S.No',
    hasSort: false,
    customCell: true,
  },
  {
    id: 'order_no',
    align: 'left',
    label: 'Order No',
    hasSort: true,
  },
  {
    id: 'created_at',
    align: 'left',
    label: 'Date',
    hasSort: true,
    customCell: true,
  },
  {
    id: 'amount',
    align: 'right',
    label: 'Bill Amount',
    hasSort: true,
  },
  {
    id: 'status',
    align: 'right',
    label: '',
    customCell: true,
  },
  {
    id: 'action',
    align: 'right',
    label: '',
    hasSort: false,
    customCell: true,
  },
];

export const billsTablePayload: ITablePayload = {
  fields: ['bid', 'order_no', 'created_at', 'amount', 'payment_mode'],
  conditions: [],
  orderBy: { field: 'created_at', direction: IOrderByDirections.DESC },
  pagination: {
    skip: 0,
    take: 20,
  },
};
