import { allProductsPayload } from 'pages/products/config';
import { allSuppliersPayload } from 'pages/suppliers/config';
import { useMemo } from 'react';
import { useFetchCategory, useProductTableData } from 'services/product.service';
import { useSupplierTableData } from 'services/supplier.service';
import { useOnlineStatus } from 'utils/useOnlineStatus';

export const useGetFormattedProducts = () => {
  const isOnline = useOnlineStatus();
  const { data: productsResponse = { results: [] } } = useProductTableData(allProductsPayload, isOnline);
  const { data: categoryList = { results: [] } } = useFetchCategory(isOnline);
  const { data: supplierList = { results: [] } } = useSupplierTableData(allSuppliersPayload);

  const supMap = useMemo(
    () => Object.assign({}, ...supplierList.results.map((sup: any) => ({ [sup.sid]: sup }))),
    [supplierList.results],
  );

  const catMap = useMemo(
    () => Object.assign({}, ...categoryList.results.map((cat: any) => ({ [cat.cat_id]: cat }))),
    [categoryList.results],
  );

  const productMap = useMemo(
    () => Object.assign({}, ...productsResponse.results.map((p: any) => ({ [p.pid]: p }))),
    [productsResponse.results],
  );

  const formattedProducts = useMemo(
    () =>
      productsResponse.results.map((i: any) => ({
        ...i,
        nameLower: i.name.toLowerCase(),
        code: `${catMap[i.cat_id]?.code}${i.code}`,
        codeLabel: `${catMap[i.cat_id]?.code}-${i.code}`,
      })),
    [productsResponse, catMap],
  );
  return {
    formattedProducts,
    categoryList: categoryList.results,
    categoryMap: catMap,
    productMap: productMap,
    supplierMap: supMap,
  };
};
