import { useMutation, useQuery } from 'react-query';
import { apiRoutes } from '../constants/api.constant';
import apiClient from './api.service';
import { ITablePayload } from './types';

export const useSupplierTableData = (payload: ITablePayload): any => {
  return useQuery(
    [
      !!payload.pagination ? apiRoutes.SUPPLIER_LIST : `${apiRoutes.SUPPLIER_LIST}_ALL`,
      'useSupplierTableData',
      payload,
    ],
    () => getSupplierTableData(payload),
  );
};

export const useSupplierDetail = (supplierId: number): any => {
  return useQuery([apiRoutes.SUPPLIER_LIST, 'useSupplierDetail', supplierId], () => getSupplierDetail(supplierId), {
    enabled: !!supplierId,
  });
};

export const useSaveSupplier = () => useMutation(saveSupplier);
export const useUpdateSupplier = () => useMutation(updateSupplier);
export const useDeleteSupplier = () => useMutation(deleteSupplier);

const getSupplierTableData = async (payload: any) => await apiClient.post(apiRoutes.SUPPLIER_LIST, payload);
const getSupplierDetail = async (supplierId: number) => await apiClient.get(`${apiRoutes.SUPPLIERS}/${supplierId}`);
const saveSupplier = async (data: any) => await apiClient.post(apiRoutes.SUPPLIERS, data);
const updateSupplier = async (data: any) => await apiClient.post(`${apiRoutes.SUPPLIERS}/${data.sid}`, data);
const deleteSupplier = async (supplierId: number) => await apiClient.delete(`${apiRoutes.SUPPLIERS}/${supplierId}`);
