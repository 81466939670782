import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import globalConstants from 'constants/global.constants';
import Database from 'database/database';
import { useState } from 'react';
import { useOnlineStatus } from 'utils/useOnlineStatus';
import MainCard from '../../components/MainCard';
import { STATUS_CODES } from '../../constants/api.constant';
import { usePostLogin } from '../../services/auth.service';
import { useAppDispatch } from '../../store';
import { setAuthDetails } from '../../store/auth';
import Logo from '../landing/Drawer/DrawerHeader/Logo';
import Styles from './styles';

const Login = () => {
  const loginForm = usePostLogin();
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<any>({ email: '', password: '' });
  const [formError, setFormError] = useState<any>({});
  const isOnline = useOnlineStatus();

  const handleResponse = async (response: any) => {
    const db = await Database.get();
    await db.users.upsert({
      ...response,
      pwd: formData.password,
    });
    localStorage.setItem(globalConstants.TOKEN_STORAGE_KEY, response.token);
    dispatch(setAuthDetails({ loggedIn: true, loginVerified: true, ...response }));
  };

  const handleSubmit = async () => {
    if (!isOnline) {
      const db = await Database.get();
      const user = await db.users
        .findOne({
          selector: {
            email: formData.email,
            pwd: formData.password,
          },
        })
        .exec();
      if (!!user) {
        handleResponse(user.toJSON());
      } else {
        setFormError({ email: 'Not available. Please connect internet' });
      }
    } else {
      loginForm.mutate(formData, {
        onSuccess: (response: any) => handleResponse(response),
        onError: (error: any) => {
          if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
            setFormError(error.data.errors);
          }
        },
      });
    }
  };

  return (
    <>
      <Styles.LoginBackground />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Styles.LoginLogo>
            <Logo />
          </Styles.LoginLogo>
          <MainCard
            sx={{
              maxWidth: { xs: 400, lg: 475 },
              marginTop: { xs: 2.5, md: 3 },
              padding: 3,
            }}
            content={true}
            border={false}
            boxShadow
            shadow={(theme: any) => theme.customShadows.z1}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box component={'div'} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Username"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={formData.email}
                    error={!!formError?.email}
                    helperText={formError?.email}
                    onChange={event => setFormData({ ...formData, email: event.target.value })}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={formData.password}
                    error={!!formError?.password}
                    helperText={formError?.password}
                    onChange={event => setFormData({ ...formData, password: event.target.value })}
                  />
                  {loginForm.isLoading ? (
                    <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                      <CircularProgress size="1rem" style={{ color: '#fff' }} sx={{ mr: 1 }} /> &nbsp;
                    </Button>
                  ) : (
                    <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleSubmit()}>
                      Sign In
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </MainCard>
        </Box>
      </Container>
    </>
  );
};
export default Login;
