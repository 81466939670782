export const currencyFormat = (x: number, options?: any) => {
  return `₹${numberFormat(x, options)}`;
};
export const numberFormat = (x: number, options?: any) => {
  if (!x) {
    return x;
  }
  let val = x.toString();
  if (!!options?.decimal) {
    val = parseFloat(val).toFixed(2);
  }
  return val.replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,');
};
