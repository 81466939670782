import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DoneIcon from '@mui/icons-material/Done';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { Button, Drawer, Grid, IconButton, TableCell, TextField, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { RupeeIcon } from 'assets/icons/rupee';
import { UpiICon } from 'assets/icons/upi';
import MainCard from 'components/MainCard';
import IndexTable from 'components/index-table';
import { appRoutes } from 'constants/routes.constant';
import Database from 'database/database';
import useDebounce from 'hooks/useDebounce';
import BillSummary from 'pages/bills-form/bill-summary';
import { GlobalStyle } from 'pages/bills-form/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBillsTableData } from 'services/bills.service';
import { timeFormat } from 'utils/dateFormat';
import { useOnlineStatus } from 'utils/useOnlineStatus';
import { billsTableConfig, billsTablePayload } from './config';

const Bills = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const isOnline = useOnlineStatus();

  const [payload, setPayload] = useState(billsTablePayload);
  const { data, isFetching } = useBillsTableData(payload, isOnline);
  const [summaryInfo, setSummaryInfo] = useState({
    isVisible: false,
    selectedBill: 0,
  });
  const [localData, setLocalData] = useState({});
  useEffect(() => {
    if (isOnline && payload.pagination?.skip === 0 && data) {
      getLocalData();
    } else {
      setLocalData(data);
    }
  }, [isOnline, payload, data]);

  const getLocalData = async () => {
    const db = await Database.get();
    const bills = await db.bills
      .find({
        selector: { local_data: 1 },
        sort: [{ created_at: 'desc' }],
      })
      .exec();
    setLocalData({ ...data, results: [...bills.map((i: any) => i.toJSON()), ...data.results] });
  };
  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (debouncedValue) {
      setPayload({
        ...payload,
        conditions: [
          ...payload.conditions.filter(p => p.field !== 'name'),
          { field: 'name', condition: 'LIKE', value: `%${debouncedValue}%` },
        ],
      });
    } else {
      setPayload({ ...payload, conditions: payload.conditions.filter(p => p.field !== 'name') });
    }
  }, [debouncedValue]);

  const getCustomCell = (column: any, row: any, rowIndex: number, columnIndex: number) => {
    if (column.id === 'sno') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {(billsTablePayload?.pagination?.skip || 0) + rowIndex + 1}
        </TableCell>
      );
    }
    if (column.id === 'created_at') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {timeFormat(row.created_at)}
        </TableCell>
      );
    }
    if (column.id === 'status') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          <Button aria-label="delete" color="primary" variant={'text'}>
            {row.payment_mode === 'U' ? <UpiICon /> : <RupeeIcon />}
          </Button>
          <IconButton
            aria-label="delete"
            color="inherit"
            onClick={() => setSummaryInfo({ selectedBill: row.bid, isVisible: true })}
          >
            {row.local_data === 1 ? <SyncDisabledIcon /> : <DoneIcon />}
          </IconButton>
        </TableCell>
      );
    }
    return (
      <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
        <IconButton aria-label="delete" color="primary" onClick={() => handleOnClick(row)}>
          <ChevronRightIcon />
        </IconButton>
      </TableCell>
    );
  };

  const handleOnClick = (row: any) => {
    setSummaryInfo({ selectedBill: row.bid, isVisible: true });
  };
  return (
    <>
      <GlobalStyle />
      <Grid container justifyContent="space-between" alignItems="center" sx={{ pb: 2, gap: 1 }}>
        <MuiBreadcrumbs aria-label="breadcrumb">
          <Typography color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
            Transaction
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            Bills
          </Typography>
        </MuiBreadcrumbs>
        <Grid item>
          <Grid item container alignItems="center" gap={1}>
            <TextField
              autoFocus
              id="outlined-basic"
              placeholder="Search"
              value={searchTerm}
              variant="outlined"
              onChange={handleChange}
            />
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => navigate(appRoutes.NEW_BILL)}
            >
              New Bill
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <MainCard>
        <IndexTable
          isFetching={isFetching}
          columns={billsTableConfig}
          data={localData}
          payload={payload}
          getCustomCell={getCustomCell}
          onPayloadChange={(newPayload: any) => setPayload(newPayload)}
          clearFilters={() => {
            setSearchTerm('');
            setPayload({ ...payload, conditions: [] });
          }}
          onRowClick={(row: any) => handleOnClick(row)}
        />
      </MainCard>
      <Drawer
        variant="temporary"
        open={summaryInfo.isVisible}
        ModalProps={{ keepMounted: true }}
        anchor="right"
        onClose={() => setSummaryInfo({ ...summaryInfo, isVisible: false })}
      >
        {summaryInfo.isVisible && <BillSummary bid={summaryInfo.selectedBill} />}
      </Drawer>
    </>
  );
};

export default Bills;
