import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx = {}, to = '/' }) => (
  <ButtonBase disableRipple component={Link} to={!to ? '/' : to} sx={{ ...sx, fontSize: 20, fontWeight: 700, gap: 1 }}>
    <img src="/assets/images/logo.png" alt="logo" width={'70%'} />
  </ButtonBase>
);

export default LogoSection;
