import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
`;

const CalenderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 18px;
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
const TableContainer = styled.div`
  display: flex;
  width: 100%;
`;

const DateCellHeader = styled.div`
  display: flex;
  flex-direction: column;
  .today {
    color: #dc004e;
  }
`;
const AttendanceStyles = {
  Container,
  CalenderContainer,
  Actions,
  TableContainer,
  DateCellHeader,
};

export default AttendanceStyles;
