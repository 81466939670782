import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Grid, TableCell, TableRow, TextField, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import MainCard from 'components/MainCard';
import IndexTable from 'components/index-table';
import { STATUS_CODES } from 'constants/api.constant';
import { endOfDay, startOfMonth } from 'date-fns';
import dayjs from 'dayjs';
import useReportHelper from 'hooks/useReportHelper';
import { GlobalStyle } from 'pages/bills-form/styles';
import SupplierInput from 'pages/suppliers/supplier-input';
import { useState } from 'react';
import { useSupplierReport } from 'services/reports.service';
import { timeFormat } from 'utils/dateFormat';
import { numberFormat } from 'utils/numberFormat';
import ReportStyles from './styles';

const reportPayload = {
  from: startOfMonth(new Date()).toISOString(),
  to: endOfDay(new Date()).toISOString(),
  sid: 0,
  conditions: [],
};
export const tableConfig = [
  {
    id: 'sno',
    align: 'left',
    label: 'S.No',
    hasSort: false,
    customCell: true,
  },
  {
    id: 'created_at',
    align: 'left',
    label: 'Date',
    hasSort: true,
    customCell: true,
  },
  {
    id: 'payable_amount',
    align: 'right',
    label: 'Purchase',
    hasSort: true,
  },
  {
    id: 'amount',
    align: 'right',
    label: 'Payment',
    hasSort: true,
  },
];

const SupplierReport = () => {
  const [payload, setPayload] = useState(reportPayload);
  const supplierReport = useSupplierReport();
  const [tableData, setTableData] = useState<any>();
  const { exportTable } = useReportHelper();

  const onDateChange = (dVal: dayjs.Dayjs | null, key: string) => {
    setPayload({ ...payload, [key]: dVal?.toISOString() });
  };

  const onSelectionChange = (e: any) => {
    setPayload({ ...payload, sid: e.target.value });
  };

  const fetchReport = () => {
    supplierReport.mutate(payload, {
      onSuccess: (response: any) => {
        setTableData(response);
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          console.log(error);
        }
      },
    });
  };

  const getCustomCell = (column: any, row: any, rowIndex: number, columnIndex: number) => {
    if (column.id === 'sno') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {rowIndex + 1}
        </TableCell>
      );
    }
    if (column.id === 'created_at') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {timeFormat(row[column.id])}
        </TableCell>
      );
    }
    return (
      <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
        {row[column.id]}
      </TableCell>
    );
  };

  return (
    <>
      <GlobalStyle />
      <Grid container justifyContent="space-between" alignItems="center" sx={{ pb: 2, gap: 1 }}>
        <MuiBreadcrumbs aria-label="breadcrumb">
          <Typography color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
            Reports
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            Supplier
          </Typography>
        </MuiBreadcrumbs>
        <Grid item>
          <Grid item container alignItems="center" gap={1}>
            <div style={{ minWidth: 300 }}>
              <SupplierInput
                value={payload.sid}
                onChange={(event: any) => onSelectionChange(event)}
                error={false}
                helperText=""
              />
            </div>

            <MobileDateTimePicker
              label="From"
              inputFormat="DD MMM, YYYY hh:mm A"
              value={dayjs(payload.from)}
              onChange={v => null}
              onAccept={v => onDateChange(v, 'from')}
              renderInput={params => <TextField {...params} />}
            />
            <MobileDateTimePicker
              label="To"
              inputFormat="DD MMM, YYYY hh:mm A"
              value={dayjs(payload.to)}
              onChange={v => null}
              onAccept={v => onDateChange(v, 'to')}
              renderInput={params => <TextField {...params} />}
            />
            <Button variant="contained" size="small" startIcon={<FilterListIcon />} onClick={() => fetchReport()}>
              Filter
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<FileDownloadIcon />}
              onClick={() => exportTable('supplierReport')}
            >
              Export
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <MainCard>
        <IndexTable
          tableId="supplierReport"
          isFetching={supplierReport.isLoading}
          columns={tableConfig}
          data={tableData}
          payload={payload}
          getCustomCell={getCustomCell}
          onPayloadChange={(newPayload: any) => setPayload(newPayload)}
          hasPagination={false}
          clearFilters={() => setPayload(reportPayload)}
          tableHeader={
            <>
              {tableData && (
                <>
                  <TableRow className="totalReportInfo">
                    <TableCell colSpan={4} align="right">
                      <ReportStyles.Label>From:</ReportStyles.Label>
                      <ReportStyles.Value>{timeFormat(tableData?.payload?.from)}</ReportStyles.Value>
                    </TableCell>
                  </TableRow>
                  <TableRow className="totalReportInfo">
                    <TableCell colSpan={4} align="right">
                      <ReportStyles.Label>To:</ReportStyles.Label>
                      <ReportStyles.Value>{timeFormat(tableData?.payload?.to)}</ReportStyles.Value>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </>
          }
          tableFooter={
            <>
              {tableData && (
                <>
                  <TableRow className="totalReportInfo">
                    <TableCell colSpan={2} align="right">
                      Total
                    </TableCell>
                    <TableCell align="right">{numberFormat(tableData?.total?.purchase)}</TableCell>
                    <TableCell align="right">{numberFormat(tableData?.total?.payment)}</TableCell>
                  </TableRow>
                  <TableRow className="totalReportInfo">
                    <TableCell colSpan={4} align="right">
                      <ReportStyles.Label>Opening Balance:</ReportStyles.Label>
                      <ReportStyles.Value>{numberFormat(tableData?.total?.opening_balance)}</ReportStyles.Value>
                    </TableCell>
                  </TableRow>
                  <TableRow className="totalReportInfo">
                    <TableCell colSpan={4} align="right">
                      <ReportStyles.Label>Total purchase:</ReportStyles.Label>
                      <ReportStyles.Value>{numberFormat(tableData?.total?.purchase)}</ReportStyles.Value>
                    </TableCell>
                  </TableRow>
                  <TableRow className="totalReportInfo">
                    <TableCell colSpan={4} align="right">
                      <ReportStyles.Label>Total payment:</ReportStyles.Label>
                      <ReportStyles.Value>{numberFormat(tableData?.total?.payment)}</ReportStyles.Value>
                    </TableCell>
                  </TableRow>
                  <TableRow className="totalReportInfo">
                    <TableCell colSpan={4} align="right">
                      <ReportStyles.Label>Closing balance:</ReportStyles.Label>
                      <ReportStyles.Value>{numberFormat(tableData?.total?.closing_balance)}</ReportStyles.Value>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </>
          }
        />
      </MainCard>
    </>
  );
};

export default SupplierReport;
