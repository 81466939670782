import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Modal,
  TextField,
} from '@mui/material';
import MainCard from 'components/MainCard';
import ModalBox from 'components/modal-box/ModalBox';
import ModalBoxstyles from 'components/modal-box/styles';
import { STATUS_CODES, apiRoutes } from 'constants/api.constant';
import globalConstants from 'constants/global.constants';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useDeleteSupplier, useSaveSupplier, useSupplierDetail, useUpdateSupplier } from 'services/supplier.service';
import { showToast } from 'store/global';
import { allSuppliersPayload } from './config';

interface ISupplierForm {
  formConfig: any;
  onClose: Function;
}

const DEFAULT_FORM_DATA = {
  name: '',
  code: '',
  contact_person: '',
  mobile: '',
  address_1: '',
  address_2: '',
  city: '',
  state: '33',
  gst: '',
  opening_balance: 0,
};
const SupplierForm = ({ onClose, formConfig }: ISupplierForm) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>(DEFAULT_FORM_DATA);
  const [formError, setFormError] = useState<any>({});
  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);

  const inputRef: any = useRef(null);
  const { data: supplierDetailResponse, isFetching } = useSupplierDetail(formConfig.formId);

  const saveSupplier = useSaveSupplier();
  const updateSupplier = useUpdateSupplier();
  const deleteSupplier = useDeleteSupplier();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, [inputRef]);

  useEffect(() => {
    if (supplierDetailResponse) {
      setFormData({ ...DEFAULT_FORM_DATA, ...supplierDetailResponse });
    }
    return () => {
      setFormData({ ...DEFAULT_FORM_DATA });
    };
  }, [supplierDetailResponse]);

  useEffect(() => {
    if (!!formConfig.formData) {
      setFormData({ ...DEFAULT_FORM_DATA, ...formConfig.formData });
    }
  }, [formConfig]);

  const setInputValue = (event: any, field: string, inputType = globalConstants.INPUT_TYPE.TEXT) => {
    event.preventDefault();
    const value = event.target.value;
    if (inputType === globalConstants.INPUT_TYPE.TEXT) {
      setFormData({ ...formData, [field]: value });
    } else {
      const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
      if (value === '' || value === '-' || re.test(value)) {
        setFormData({ ...formData, [field]: value });
      }
    }
    setFormError({ ...formError, [field]: '' });
  };

  const onSave = () => {
    saveSupplier.mutate(formData, {
      onSuccess: (response: any) => {
        dispatch(showToast({ type: 'success', message: 'Supplier created' }));

        queryClient.invalidateQueries({ queryKey: [apiRoutes.SUPPLIER_LIST], refetchActive: true });
        queryClient.setQueryData(
          [`${apiRoutes.SUPPLIER_LIST}_ALL`, 'useSupplierTableData', allSuppliersPayload],
          (original: any) => ({
            ...original,
            results: [...original.results, { ...formData, sid: response?.insertId }],
          }),
        );

        onClose(response?.insertId);
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  const onUpdate = () => {
    updateSupplier.mutate(formData, {
      onSuccess: () => {
        dispatch(showToast({ type: 'success', message: 'Supplier details updated' }));
        queryClient.invalidateQueries({ queryKey: [apiRoutes.SUPPLIER_LIST], refetchActive: true });
        queryClient.setQueryData(
          [`${apiRoutes.SUPPLIER_LIST}_ALL`, 'useSupplierTableData', allSuppliersPayload],
          (original: any) => ({
            ...original,
            results: original.results.map((i: any) => (i.sid === formData.sid ? { ...formData } : i)),
          }),
        );
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  const onDelete = () => {
    toggleDeleteConfirmation(false);
    deleteSupplier.mutate(formData.sid, {
      onSuccess: () => {
        dispatch(showToast({ type: 'warning', message: 'Supplier details deleted' }));
        queryClient.invalidateQueries({
          queryKey: [apiRoutes.SUPPLIER_LIST, 'useSupplierTableData'],
          refetchActive: true,
        });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        } else if (error.status === STATUS_CODES.BAD_REQUEST) {
          dispatch(showToast({ type: 'error', message: error.data.errors }));
        }
      },
    });
  };

  return (
    <>
      <Modal
        open={formConfig.isOpen}
        hideBackdrop
        onClose={() => onClose()}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div>
          <ModalBox>
            <Backdrop
              sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
              open={saveSupplier.isLoading || updateSupplier.isLoading || deleteSupplier.isLoading || isFetching}
            >
              <CircularProgress color="primary" />
            </Backdrop>
            <MainCard
              title={
                formConfig.formType === globalConstants.FORM_TYPE.CREATE
                  ? 'Create supplier form'
                  : 'Update supplier form'
              }
              secondary={
                formConfig.formType === globalConstants.FORM_TYPE.CREATE ? (
                  <ModalBoxstyles.ActionContainer>
                    <Button variant="contained" size="small" startIcon={<SaveIcon />} onClick={onSave}>
                      Save
                    </Button>
                    <Button color="secondary" size="small" startIcon={<CancelIcon />} onClick={() => onClose()}>
                      Cancel
                    </Button>
                  </ModalBoxstyles.ActionContainer>
                ) : (
                  <ModalBoxstyles.ActionContainer>
                    <Button
                      color="warning"
                      variant="contained"
                      size="small"
                      startIcon={<UpdateIcon />}
                      onClick={onUpdate}
                    >
                      Update
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      startIcon={<DeleteForeverIcon />}
                      onClick={() => toggleDeleteConfirmation(true)}
                    >
                      Delete
                    </Button>
                    <Button color="secondary" size="small" startIcon={<CancelIcon />} onClick={() => onClose()}>
                      Cancel
                    </Button>
                  </ModalBoxstyles.ActionContainer>
                )
              }
            >
              <Grid container spacing={0}>
                <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', gap: 2 }}>
                    <TextField
                      label="Supplier Name"
                      value={formData.name}
                      autoComplete="off"
                      inputRef={inputRef}
                      error={formError.name?.length > 0}
                      helperText={formError.name}
                      onChange={event => setInputValue(event, 'name')}
                    />
                    {/* <TextField
                      label="Code"
                      value={formData.code}
                      autoComplete="off"
                      error={formError.code?.length > 0}
                      helperText={formError.code}
                      onChange={event => setInputValue(event, 'code')}
                    /> */}
                    <TextField
                      label="Contact person name"
                      value={formData.contact_person}
                      autoComplete="off"
                      error={formError.contact_person?.length > 0}
                      helperText={formError.contact_person}
                      onChange={event => setInputValue(event, 'contact_person')}
                    />
                    <TextField
                      label="Mobile no"
                      value={formData.mobile}
                      autoComplete="off"
                      error={formError.mobile?.length > 0}
                      helperText={formError.mobile}
                      onChange={event => setInputValue(event, 'mobile')}
                    />
                    <TextField
                      label="Address"
                      value={formData.address_1}
                      autoComplete="off"
                      error={formError.address_1?.length > 0}
                      helperText={formError.address_1}
                      onChange={event => setInputValue(event, 'address_1')}
                    />
                    <TextField
                      label=""
                      value={formData.address_2}
                      autoComplete="off"
                      error={formError.address_2?.length > 0}
                      helperText={formError.address_2}
                      onChange={event => setInputValue(event, 'address_2')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ padding: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', gap: 2 }}>
                    <TextField
                      label="City"
                      value={formData.city}
                      autoComplete="off"
                      error={formError.city?.length > 0}
                      helperText={formError.city}
                      onChange={event => setInputValue(event, 'city')}
                    />
                    <TextField
                      label="State"
                      select
                      value={formData.state}
                      autoComplete="off"
                      error={formError.state?.length > 0}
                      helperText={formError.state}
                      onChange={event => setInputValue(event, 'state')}
                    >
                      {globalConstants.STATES.map(option => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      label="Gst"
                      value={formData.gst}
                      autoComplete="off"
                      error={formError.gst?.length > 0}
                      helperText={formError.gst}
                      onChange={event => setInputValue(event, 'gst')}
                    />
                    <TextField
                      label="Opening Balance"
                      value={formData.opening_balance}
                      autoComplete="off"
                      error={formError.opening_balance?.length > 0}
                      helperText={formError.opening_balance}
                      onChange={event => setInputValue(event, 'opening_balance', globalConstants.INPUT_TYPE.NUMBER)}
                    />
                  </Box>
                </Grid>
              </Grid>
            </MainCard>
          </ModalBox>
        </div>
      </Modal>
      <Dialog open={showDeleteConfirmation} onClose={() => toggleDeleteConfirmation(false)}>
        <DialogTitle>{'Are you sure to Delete?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Once delete you can't restore data!.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={onDelete} autoFocus color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SupplierForm;
