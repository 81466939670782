import { Box } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Palette from 'themes/palette';
import Drawer from './Drawer';
import Header from './Header';

const Landing = () => {
  // const [open, setOpen] = useState(drawerOpen);
  const theme = Palette('dark');
  const [open, setOpen] = useState(true);
  const handleDrawerToggle = () => {
    setOpen(!open);
    // dispatch(openDrawer({ drawerOpen: !open }));
  };

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box component="main" sx={{ width: '100%', marginTop: 6, flexGrow: 1, p: { xs: 1, sm: 2 } }}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
};

export default Landing;
