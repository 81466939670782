import styled from 'styled-components';

const LoginBackground = styled.div`
  background-image: url(/assets/images/login-bg.png);
  background-repeat: repeat;
  background-color: #004827;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(8px);
`;

const LoginLogo = styled.div`
  .loginText {
    color: #fff !important;
  }
`;

const styles = { LoginBackground, LoginLogo };

export default styles;
