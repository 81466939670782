import styled, { createGlobalStyle } from 'styled-components';

const NoDataContainer = styled.div`
  display: flex;
  height: 350px;
  padding-top: 75px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    font-weight: 500;
    font-size: 20px;
  }
`;

const IndexTableStyles = createGlobalStyle`
  table.indexTable {
    .MuiIconButton-root{
      height: 18px;
    }
    .tableRow{
      cursor: pointer;
    }
  }
`;

const styles = { NoDataContainer, IndexTableStyles };

export default styles;
