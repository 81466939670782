import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import authReducer from './auth';
import billsReducer from './bills';
import globalReducer from './global';

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  bills: billsReducer,
  //  if we need to use more reducers
});
const store = configureStore({ reducer: rootReducer });

export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
