import globalConstants from 'constants/global.constants';
import { IOrderByDirections, ITablePayload } from 'services/types';

export const supplierTableConfig = [
  {
    id: 'sno',
    align: 'left',
    label: 'S.No',
    hasSort: false,
    customCell: true,
  },
  {
    id: 'name',
    align: 'left',
    label: 'Name',
    hasSort: true,
  },
  {
    id: 'contact_person',
    align: 'left',
    label: 'Contact',
    hasSort: true,
  },
  {
    id: 'city',
    align: 'left',
    label: 'City',
    hasSort: true,
  },
  {
    id: 'closing_balance',
    align: 'right',
    label: 'Closing Balance',
    hasSort: true,
  },
  {
    id: 'action',
    align: 'right',
    label: '',
    hasSort: false,
    customCell: true,
  },
];

export const supplierTablePayload: ITablePayload = {
  fields: ['sid', 'name', 'code', 'contact_person', 'city', 'closing_balance'],
  conditions: [],
  orderBy: { field: 'changed_at', direction: IOrderByDirections.DESC },
  pagination: {
    skip: 0,
    take: 20,
  },
};

export const defaultFormData = {
  formType: globalConstants.FORM_TYPE.CREATE,
  isOpen: false,
  formId: null,
};

export const allSuppliersPayload: ITablePayload = {
  fields: ['sid', 'name'],
  conditions: [],
  orderBy: { field: 'name', direction: IOrderByDirections.ASC },
};
