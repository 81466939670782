/* eslint-disable */
// @ts-nocheck
import { useRef, useState } from 'react';

// material-ui
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';

import Person2Icon from '@mui/icons-material/Person2';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
// project import
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LogoutIcon from '@mui/icons-material/Logout';
import globalConstants from 'constants/global.constants';
import { useSelector } from 'react-redux';
import { useSyncBills } from 'services/bills.service';
import { globalSelector, showToast, toggleThemeMode } from 'store/global';
import { useOnlineStatus } from 'utils/useOnlineStatus';
import MainCard from '../../../components/MainCard';
import Transitions from '../../../components/Transitions';
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';
import { useAppDispatch } from '../../../store';
import { authSelector, setAuthDetails } from '../../../store/auth';

// assets

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { name } = useSelector(authSelector);
  const { isDarkTheme } = useSelector(globalSelector);
  const { syncBills } = useSyncBills();
  const isOnline = useOnlineStatus();
  const [openLoader, setOpenLoader] = useState(false);

  const handleLogout = async () => {
    localStorage.removeItem(globalConstants.TOKEN_STORAGE_KEY);
    dispatch(setAuthDetails({ loggedIn: false, loginVerified: true, token: '' }));
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const onUpdateSw = () => {
    setOpenLoader(true);
    serviceWorkerRegistration.update();
  };

  const triggerSync = () => {
    if (!isOnline) {
      dispatch(showToast({ type: 'error', message: 'You are offline' }));
    } else {
      setOpenLoader(true);
      syncBills();
    }
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Button
        color="secondary"
        variant="text"
        sx={{
          p: 0.25,
          borderRadius: 1,
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack color={'#fff'} direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <AccountCircleIcon />
          <Typography variant="subtitle1">{name}</Typography>
        </Stack>
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 200,
                  minWidth: 200,
                  maxWidth: 200,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 200,
                  },
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <span>
                    <MainCard elevation={0} border={false} content={false}>
                      {open && (
                        <>
                          <List
                            component="nav"
                            sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}
                          >
                            <ListItemButton selected={false} onClick={() => triggerSync()}>
                              <ListItemIcon>
                                <SyncDisabledIcon />
                              </ListItemIcon>
                              <ListItemText primary="Sync Data" />
                            </ListItemButton>
                            <ListItemButton selected={false} onClick={event => null}>
                              <ListItemIcon>
                                <Person2Icon />
                              </ListItemIcon>
                              <ListItemText primary="View Profile" />
                            </ListItemButton>
                            <ListItemButton selected={false} onClick={event => dispatch(toggleThemeMode())}>
                              <ListItemIcon>{isDarkTheme ? <LightModeIcon /> : <DarkModeIcon />}</ListItemIcon>
                              <ListItemText primary="Change Theme" />
                            </ListItemButton>
                            <ListItemButton selected={false} onClick={() => onUpdateSw()}>
                              <ListItemIcon>
                                <SyncDisabledIcon />
                              </ListItemIcon>
                              <ListItemText primary="Update Software" />
                            </ListItemButton>
                            <ListItemButton selected={false} onClick={handleLogout}>
                              <ListItemIcon>
                                <LogoutIcon />
                              </ListItemIcon>
                              <ListItemText primary="Logout" />
                            </ListItemButton>
                          </List>
                        </>
                      )}
                    </MainCard>
                  </span>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
      <Dialog
        open={openLoader}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Sync is in progress'}</DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Profile;
