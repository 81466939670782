import { IOrderByDirections, ITablePayload } from 'services/types';

export const paymentsTableConfig = [
  {
    id: 'sno',
    align: 'left',
    label: 'S.No',
    hasSort: false,
    customCell: true,
  },
  {
    id: 'created_at',
    align: 'left',
    label: 'Date',
    hasSort: true,
    customCell: true,
  },
  {
    id: 'supplier',
    align: 'left',
    label: 'Supplier',
    customCell: true,
  },
  {
    id: 'amount',
    align: 'right',
    label: 'Amount',
    hasSort: true,
  },
  {
    id: 'action',
    align: 'right',
    label: '',
    hasSort: false,
    customCell: true,
  },
];

export const paymentsTablePayload: ITablePayload = {
  fields: ['pa_id', 'created_at', 'sid', 'amount'],
  conditions: [],
  orderBy: { field: 'created_at', direction: IOrderByDirections.DESC },
  pagination: {
    skip: 0,
    take: 20,
  },
};
