export const appRoutes = {
  LANDING: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  BILLS: '/bills',
  PURCHASE: '/purchase',
  PAYMENTS: '/payments',
  NEW_BILL: '/bills/new',
  PRODUCTS: '/products',
  SUPPLIERS: '/suppliers',
  ATTENDANCE: '/attendance',
  SALES_REPORT: '/reports/sales',
  TRANSACTION_REPORT: '/reports/transaction',
  SUPPLIER_REPORT: '/reports/supplier',
  ERROR: '/500',
  NOT_FOUND: '*',
};
