export const RupeeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88" width={25} height={25}>
    <path d="M61.44 0A61.46 61.46 0 1 1 18 18 61.21 61.21 0 0 1 61.44 0Z" style={{ fill: '#fecb00' }} />
    <path
      d="M61.44 8.74a52.69 52.69 0 0 1 52.7 52.7v.92a50.86 50.86 0 1 0-51.77 51.77h-.92a52.7 52.7 0 0 1 0-105.4Z"
      style={{
        fillRule: 'evenodd',
        fill: '#d08b00',
      }}
    />
    <path
      d="M63.28 12.41a50.87 50.87 0 1 1-50.87 50.87 50.87 50.87 0 0 1 50.87-50.87Z"
      style={{
        fill: '#db9300',
        fillRule: 'evenodd',
      }}
    />
    <path
      d="m42.31 58.09 3.78-8.68h14.25a5.55 5.55 0 0 0-.94-1.82 7 7 0 0 0-1.66-1.54 9.19 9.19 0 0 0-2.21-1 7.88 7.88 0 0 0-2.53-.46H42.31l3.78-8.69h40.8l-3.78 8.69h-9.86a6.87 6.87 0 0 1 1.09 1.07 8.56 8.56 0 0 1 .89 1.25 13 13 0 0 1 .64 1.3 5.38 5.38 0 0 1 .34 1.2h10.68l-3.78 8.68h-7.73a14.09 14.09 0 0 1-2.53 4.19A20.71 20.71 0 0 1 69 65.83a25.24 25.24 0 0 1-4.85 2.65 23.16 23.16 0 0 1-5.31 1.45l21.39 22.51h-17L44.17 71v-8h8.46a7.13 7.13 0 0 0 2.4-.41 9 9 0 0 0 2.19-1.1A8.24 8.24 0 0 0 59 60a5.84 5.84 0 0 0 1-1.89Z"
      style={{
        fill: '#b17600',
      }}
    />
    <path
      d="m39.67 55.86 3.78-8.51H57.7a5.38 5.38 0 0 0-.94-1.78 6.56 6.56 0 0 0-1.67-1.51 8.93 8.93 0 0 0-2.2-1 8.14 8.14 0 0 0-2.58-.39H39.67l3.78-8.52h40.8l-3.78 8.52h-9.86a6.65 6.65 0 0 1 1.09 1 9 9 0 0 1 .89 1.23 12.59 12.59 0 0 1 .64 1.27 6 6 0 0 1 .34 1.18h10.68l-3.78 8.51h-7.73A14.24 14.24 0 0 1 70.21 60a20.48 20.48 0 0 1-3.91 3.48A24.76 24.76 0 0 1 61.45 66a23.51 23.51 0 0 1-5.3 1.42l21.44 22.11h-17l-19.12-21v-7.79H50a7.37 7.37 0 0 0 2.4-.4 9.33 9.33 0 0 0 2.19-1.09 8.11 8.11 0 0 0 1.74-1.55 5.42 5.42 0 0 0 1-1.84Z"
      style={{ fill: '#fecb00' }}
    />
  </svg>
);
