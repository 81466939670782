import Database from 'database/database';
import { useMutation, useQuery } from 'react-query';
import { apiRoutes } from '../constants/api.constant';
import apiClient from './api.service';
import { ITablePayload } from './types';

export const useProductTableData = (payload: ITablePayload, isOnline: boolean = true): any => {
  return useQuery([apiRoutes.PRODUCT_LIST, 'useProductTableData', payload, isOnline], () => {
    if (!!isOnline) {
      return getProductTableData(payload);
    } else {
      return getProductTableData_db();
    }
  });
};

export const useProductDetail = (productId: number): any => {
  return useQuery([apiRoutes.PRODUCT_LIST, 'useProductDetail', productId], () => getProductDetail(productId), {
    enabled: !!productId,
  });
};

export const useSaveProduct = () => useMutation(saveProduct);
export const useUpdateProduct = () => useMutation(updateProduct);
export const useDeleteProduct = () => useMutation(deleteProduct);

export const useFetchCategory = (isOnline: boolean = true): any => {
  return useQuery([apiRoutes.CATEGORY_LIST, isOnline], () => {
    if (!!isOnline) {
      return getCategoryData();
    } else {
      return getCategoryData_db();
    }
  });
};

const getProductTableData = async (payload: any) => await apiClient.post(apiRoutes.PRODUCT_LIST, payload);
const getProductDetail = async (productId: number) => await apiClient.get(`${apiRoutes.PRODUCTS}/${productId}`);
const saveProduct = async (data: any) => await apiClient.post(apiRoutes.PRODUCTS, data);
const updateProduct = async (data: any) => await apiClient.post(`${apiRoutes.PRODUCTS}/${data.formId}`, data);
const deleteProduct = async (productId: number) => await apiClient.delete(`${apiRoutes.PRODUCTS}/${productId}`);
const getCategoryData = async () => await apiClient.get(`${apiRoutes.CATEGORY_LIST}`);

const getCategoryData_db = async () => {
  const db = await Database.get();
  const category = await db.category
    .find({
      selector: {},
      sort: [{ cat_id: 'asc' }],
    })
    .exec();
  return new Promise(function (resolve, reject) {
    resolve({
      results: category.map((c: any) => ({ cat_id: c.cat_id, code: c.code, img: c.img, name: c.name })),
      totalCount: category.length,
    });
  });
};

const getProductTableData_db = async () => {
  const db = await Database.get();
  const products = await db.products
    .find({
      selector: {},
      sort: [{ pid: 'asc' }],
    })
    .exec();
  return new Promise(function (resolve, reject) {
    resolve({
      results: products.map((c: any) => ({
        cat_id: c.cat_id,
        code: c.code,
        cost: c.cost,
        gst: c.gst,
        hsn: c.hsn,
        name: c.name,
        pid: c.pid,
        rate: c.rate,
        tax_value: c.tax_value,
      })),
      totalCount: products.length,
    });
  });
};
