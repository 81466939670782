import styled from 'styled-components';

const PrintView = styled.div`
  display: none;
`;
const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 20px 15px 20px;
  background: #fff;
  color: #000;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  th {
    font-weight: normal;
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  .pad {
    padding-right: 10px;
  }
  .total {
    font-size: 12px;
    font-weight: 500;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    margin-top: 0px;
    margin-bottom: -20px;
    width: 30%;
  }
`;
const DateInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.div`
  border-bottom: 1px dashed #000;
  margin: 4px 0px;
`;

const BillStyles = {
  PrintView,
  Container,
  Divider,
  Header,
  DateInfo,
};

export default BillStyles;
