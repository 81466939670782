import { useMutation } from 'react-query';
import { apiRoutes } from '../constants/api.constant';
import apiClient from './api.service';

export const useSalesReport = () => useMutation(salesReport);

const salesReport = async (data: any) => await apiClient.post(`${apiRoutes.REPORT}/sales`, data);

export const useTransactionReport = () => useMutation(transactionReport);
const transactionReport = async (data: any) => await apiClient.post(`${apiRoutes.REPORT}/transactions`, data);

export const useSupplierReport = () => useMutation(supplierReport);
const supplierReport = async (data: any) => await apiClient.post(`${apiRoutes.REPORT}/supplier`, data);
