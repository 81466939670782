import UpdateIcon from '@mui/icons-material/Update';
import { Button } from '@mui/material';
import { RupeeIcon } from 'assets/icons/rupee';
import { UpiICon } from 'assets/icons/upi';
import ModalBoxstyles from 'components/modal-box/styles';
import { appRoutes } from 'constants/routes.constant';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useBillDetail } from 'services/bills.service';
import { currencyFormat } from 'utils/numberFormat';
import { useOnlineStatus } from 'utils/useOnlineStatus';
import BillPrint from './bill-print';
import FormStyles from './styles';

const BillSummary = ({ bid }: { bid: number }) => {
  const isOnline = useOnlineStatus();
  const navigate = useNavigate();
  const { data: billDetails = { bill: { amount: 0 }, items: [] } } = useBillDetail(bid, isOnline);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({ content: () => componentRef?.current });
  const onEdit = () => {
    localStorage.setItem('editData', JSON.stringify(billDetails));
    navigate(appRoutes.NEW_BILL);
  };

  return (
    <FormStyles.Summary id="section-to-print">
      <FormStyles.FormSection>
        <FormStyles.GroupLabel>
          <ModalBoxstyles.ActionContainer>
            <span>Order #{billDetails?.bill?.order_no}</span>
            {billDetails?.bill?.local_data && (
              <Button color="warning" variant="contained" size="small" startIcon={<UpdateIcon />} onClick={onEdit}>
                Update
              </Button>
            )}
          </ModalBoxstyles.ActionContainer>
        </FormStyles.GroupLabel>
        {billDetails.items.map((i: any, index: number) => (
          <FormStyles.BillItemSection key={`${i.code}_${index}`}>
            <FormStyles.BillItemName>
              <span>{i.name}</span>
            </FormStyles.BillItemName>
            <FormStyles.BillItemAction>
              <span>{i.qty}</span>
              <span>x</span>
              <span>{i.rate}</span>
              <span>=</span>
              <span className="amount">{i.amount}</span>
            </FormStyles.BillItemAction>
          </FormStyles.BillItemSection>
        ))}
      </FormStyles.FormSection>
      <FormStyles.FormFooter>
        <FormStyles.PaymentModes>
          <Button aria-label="delete" fullWidth color="primary" variant={'outlined'}>
            {billDetails?.bill?.payment_mode === 'U' ? <UpiICon /> : <RupeeIcon />}
          </Button>
        </FormStyles.PaymentModes>
        <FormStyles.TotalPriceInfo>
          <span>Total</span>
          <span className="amount">{currencyFormat(billDetails.bill.amount)}</span>
        </FormStyles.TotalPriceInfo>
        <Button fullWidth variant="contained" onClick={handlePrint}>
          Print
        </Button>
      </FormStyles.FormFooter>
      <BillPrint ref={componentRef} billDetails={billDetails} />
    </FormStyles.Summary>
  );
};

export default BillSummary;
