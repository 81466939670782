import AddIcon from '@mui/icons-material/Add';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { Button, Grid, IconButton, TableCell, TextField, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MainCard from 'components/MainCard';
import IndexTable from 'components/index-table';
import globalConstants from 'constants/global.constants';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useSupplierTableData } from 'services/supplier.service';
import { defaultFormData, supplierTableConfig, supplierTablePayload } from './config';
import SupplierForm from './supplier-form';

const Suppliers = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedValue = useDebounce<string>(searchTerm, 500);

  const [payload, setPayload] = useState(supplierTablePayload);
  const { data, isFetching } = useSupplierTableData(payload);
  const [formConfig, setFormConfig] = useState(defaultFormData);

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (debouncedValue) {
      setPayload({
        ...payload,
        conditions: [
          ...payload.conditions.filter(p => p.field !== 'name'),
          { field: 'name', condition: 'LIKE', value: `%${debouncedValue}%` },
        ],
      });
    } else {
      setPayload({ ...payload, conditions: payload.conditions.filter(p => p.field !== 'name') });
    }
  }, [debouncedValue]);

  const getCustomCell = (column: any, row: any, rowIndex: number, columnIndex: number) => {
    if (column.id === 'sno') {
      return (
        <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
          {(supplierTablePayload?.pagination?.skip || 0) + rowIndex + 1}
        </TableCell>
      );
    }
    return (
      <TableCell className={`th_col_${columnIndex}`} align={column.align} padding={'none'}>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => setFormConfig({ formType: globalConstants.FORM_TYPE.UPDATE, isOpen: true, formId: row.sid })}
        >
          <SaveAsIcon />
        </IconButton>
      </TableCell>
    );
  };
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ pb: 2, gap: 1 }}>
        <MuiBreadcrumbs aria-label="breadcrumb">
          <Typography color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
            Master
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            Suppliers
          </Typography>
        </MuiBreadcrumbs>
        <Grid item>
          <Grid item container alignItems="center" gap={1}>
            <TextField
              autoFocus
              id="outlined-basic"
              placeholder="Search"
              value={searchTerm}
              variant="outlined"
              onChange={handleChange}
            />
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setFormConfig({ ...defaultFormData, isOpen: true })}
            >
              Add Supplier
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <MainCard>
        <IndexTable
          isFetching={isFetching}
          columns={supplierTableConfig}
          data={data}
          payload={payload}
          getCustomCell={getCustomCell}
          onPayloadChange={(newPayload: any) => setPayload(newPayload)}
          clearFilters={() => {
            setSearchTerm('');
            setPayload({ ...payload, conditions: [] });
          }}
          onRowClick={(row: any) =>
            setFormConfig({ formType: globalConstants.FORM_TYPE.UPDATE, isOpen: true, formId: row.sid })
          }
        />
      </MainCard>
      {formConfig.isOpen && <SupplierForm onClose={() => setFormConfig(defaultFormData)} formConfig={formConfig} />}
    </>
  );
};

export default Suppliers;
