import { createGlobalStyle } from 'styled-components';

const AutoCompleteStyles = createGlobalStyle`
  .inputAC {
    .MuiAutocomplete-inputRoot{
      padding: 3px 9px;
    }
  }
`;

const SupplierInputStyles = { AutoCompleteStyles };

export default SupplierInputStyles;
