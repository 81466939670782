// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { globalSelector } from 'store/global';

// project import
import { ThemeProvider } from 'styled-components';
import Palette from './palette';

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

export default function ThemeCustomization({ children }) {
  const { isDarkTheme } = useSelector(globalSelector);
  const theme = useMemo(() => Palette(isDarkTheme ? 'dark' : 'light'), [isDarkTheme]);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
