import { appRoutes } from 'constants/routes.constant';
import Database from 'database/database';
import { endOfDay, startOfDay } from 'date-fns';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import store from 'store';
import { setOrderNo } from 'store/bills';
import { showToast } from 'store/global';
import { apiRoutes } from '../constants/api.constant';
import apiClient from './api.service';
import { ITablePayload } from './types';

export const useFetchOrderNo = (isOnline: boolean = true): any => {
  return useQuery(
    [apiRoutes.BILLS_ORDER_NO, 'useFetchOrderNo', isOnline],
    () => {
      if (!!isOnline) {
        return fetchOrderNo_db();
      } else {
        return fetchOrderNo_db();
      }
    },
    { enabled: false },
  );
};
export const useBillsTableData = (payload: ITablePayload, isOnline: boolean = true): any => {
  return useQuery([apiRoutes.BILLS_LIST, 'useBillsTableData', payload, isOnline], () => {
    if (!!isOnline) {
      return billsTableData(payload);
    } else {
      return billsTableData_db();
    }
  });
};

export const useBillDetail = (billId: number, isOnline: boolean = true): any => {
  return useQuery(
    [apiRoutes.BILLS_LIST, 'useBillDetail', billId],
    () => {
      if (!!isOnline && typeof billId === 'number') {
        return getBillDetail(billId);
      } else {
        return getBillDetail_db(billId);
      }
    },
    {
      enabled: !!billId,
    },
  );
};

export const useSaveBill = (): any => useMutation(saveBill_db);

const billsTableData = async (payload: any) => await apiClient.post(apiRoutes.BILLS_LIST, payload);
const getBillDetail = async (billId: number) => await apiClient.get(`${apiRoutes.BILLS}/${billId}`);

export const fetchOrderNo_db = async () => {
  const db = await Database.get();
  const bills = await db.bills
    .find({
      selector: { created_at: { $lt: endOfDay(new Date()).toISOString(), $gt: startOfDay(new Date()).toISOString() } },
      sort: [{ order_no: 'desc' }],
    })
    .exec();
  return new Promise(function (resolve, reject) {
    resolve(bills.length > 0 ? bills[0].order_no + 1 : 1);
  });
};

const billsTableData_db = async () => {
  const db = await Database.get();
  const bills = await db.bills
    .find({
      selector: {},
      sort: [{ order_no: 'desc' }],
    })
    .exec();
  return new Promise(function (resolve, reject) {
    resolve({
      results: bills,
      totalCount: bills.length,
    });
  });
};

const getBillDetail_db = async (billId: number | string) => {
  const db = await Database.get();
  const bill = await db.bills
    .findOne({
      selector: { bid: billId },
      sort: [{ order_no: 'desc' }],
    })
    .exec();
  return new Promise(function (resolve, reject) {
    resolve({ bill: bill.toJSON(), items: bill.items });
  });
};

const saveBill_db = async (formData: any) => {
  const db = await Database.get();

  let billId = `${new Date().getTime()}_${Math.random()}`;
  if (!!formData.total.bid) {
    billId = formData.total.bid;
  }
  let items = formData.items;

  const changeInfo = {
    bid: billId,
    local_data: 1,
  };
  let billForm = {
    ...formData.total,
    ...changeInfo,
    items: items.map((i: any, index: number) => ({ ...i, ...changeInfo, bdid: `${index}_${billId}` })),
  };
  await db.bills.upsert(billForm);
  const order = await fetchOrderNo_db();
  store.dispatch(setOrderNo(order));
  return true;
};

export const useSyncBills = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const syncBills = async () => {
    navigate(appRoutes.BILLS);
    const db = await Database.get();
    const bills = await db.bills
      .find({
        selector: { local_data: 1 },
        sort: [{ created_at: 'asc' }],
        skip: 0,
        limit: 20,
      })
      .exec();
    if (bills.length === 0) {
      await db.bills.cleanup();
      await db.bills.remove();
      await db.products.remove();
      await db.category.remove();

      dispatch(showToast({ type: 'success', message: 'Bills synced' }));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return;
    }
    try {
      const syncURL = apiRoutes.BILLS;
      const data = await apiClient.post(syncURL, {
        bills: bills.map((k: any) => k.toJSON()),
      });
      await db.bills
        .find({
          selector: {
            bid: {
              $in: data,
            },
          },
        })
        .remove();
      syncBills();
    } catch (error) {
      console.log('push', error);
    }
  };

  return { syncBills };
};
