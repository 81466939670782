// material-ui
import GroupsIcon from '@mui/icons-material/Groups';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import { Box, Typography } from '@mui/material';
import { appRoutes } from '../../../../../constants/routes.constant';

// project import
import { UserRole } from 'constants/global.constants';
import { useSelector } from 'react-redux';
import { authSelector } from 'store/auth';
import NavGroup from './NavGroup';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { role } = useSelector(authSelector);
  const menuItem = {
    items: [
      {
        id: 'group-dashboard',
        title: 'Home',
        type: 'group',
        allowedRoles: [UserRole.ADMIN],
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: appRoutes.DASHBOARD,
            icon: LineAxisIcon,
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'transactions',
        title: 'Transactions',
        type: 'group',
        allowedRoles: [UserRole.ADMIN, UserRole.USER],
        children: [
          {
            id: 'bills',
            title: 'Bills',
            type: 'item',
            url: appRoutes.BILLS,
            icon: VaccinesIcon,
          },
        ],
      },
      {
        id: 'transactions_2',
        title: 'Transactions',
        type: 'group',
        allowedRoles: [UserRole.ADMIN],
        children: [
          {
            id: 'purchase',
            title: 'Purchase',
            type: 'item',
            url: appRoutes.PURCHASE,
            icon: VaccinesIcon,
          },
          {
            id: 'payments',
            title: 'Payments',
            type: 'item',
            url: appRoutes.PAYMENTS,
            icon: VaccinesIcon,
          },
        ],
      },
      {
        id: 'master',
        title: 'Master',
        type: 'group',
        allowedRoles: [UserRole.ADMIN, UserRole.USER],
        children: [
          {
            id: 'products',
            title: 'Products',
            type: 'item',
            url: appRoutes.PRODUCTS,
            icon: GroupsIcon,
          },
        ],
      },
      {
        id: 'master_2',
        title: 'Master',
        type: 'group',
        allowedRoles: [UserRole.ADMIN],
        children: [
          {
            id: 'suppliers',
            title: 'Suppliers',
            type: 'item',
            url: appRoutes.SUPPLIERS,
            icon: GroupsIcon,
          },
          // {
          //   id: 'attendance',
          //   title: 'Attendance',
          //   type: 'item',
          //   url: appRoutes.ATTENDANCE,
          //   icon: GroupsIcon,
          // },
        ],
      },
      {
        id: 'report',
        title: 'Reports',
        type: 'group',
        allowedRoles: [UserRole.ADMIN],
        children: [
          {
            id: 'sales_report',
            title: 'Sales',
            type: 'item',
            url: appRoutes.SALES_REPORT,
            icon: GroupsIcon,
          },
          {
            id: 'transaction_report',
            title: 'Transaction',
            type: 'item',
            url: appRoutes.TRANSACTION_REPORT,
            icon: GroupsIcon,
          },
          {
            id: 'supplier_report',
            title: 'Supplier',
            type: 'item',
            url: appRoutes.SUPPLIER_REPORT,
            icon: GroupsIcon,
          },
        ],
      },
    ],
  };
  const navGroups = menuItem.items
    .filter(i => i.allowedRoles.includes(role))
    .map(item => {
      switch (item.type) {
        case 'group':
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Fix - Navigation Group
            </Typography>
          );
      }
    });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
