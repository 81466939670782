import { Backdrop, Box, Button, CircularProgress, Grid } from '@mui/material';
import MainCard from 'components/MainCard';
import { useFetchCategory } from 'services/product.service';
import ProductFormStyles from './styles';

interface ICategoryList {
  formData: any;
  onClose: Function;
}

const CategoryList = ({ onClose, formData }: ICategoryList) => {
  const { data: categoryList = { results: [] }, isFetching } = useFetchCategory();
  return (
    <>
      <div>
        <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={isFetching}>
          <CircularProgress color="primary" />
        </Backdrop>
        <MainCard sx={{ width: 400 }} title={'Select category'}>
          <Grid item xs={6} md={12} sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', gap: 2 }}>
              <ProductFormStyles.ChipInput>
                {categoryList.results.map((i: any) => (
                  <Button
                    variant={i.cat_id === formData.cat_id ? 'contained' : 'outlined'}
                    key={i.cat_id}
                    color={i.cat_id === formData.cat_id ? 'primary' : 'secondary'}
                    size="small"
                    onClick={() => onClose({ ...formData, cat_id: i.cat_id })}
                  >
                    {i.name}
                  </Button>
                ))}
              </ProductFormStyles.ChipInput>
            </Box>
          </Grid>
        </MainCard>
      </div>
    </>
  );
};

export default CategoryList;
