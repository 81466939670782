import styled from 'styled-components';

const ActionContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const BackDrop = styled.div`
  background-color: rgb(0 0 0 / 50%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ModalBoxstyles = { ActionContainer, BackDrop };

export default ModalBoxstyles;
