import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import MainCard from 'components/MainCard';
import ModalBoxstyles from 'components/modal-box/styles';
import { STATUS_CODES, apiRoutes } from 'constants/api.constant';
import globalConstants from 'constants/global.constants';
import { allSuppliersPayload } from 'pages/suppliers/config';
import SupplierInput from 'pages/suppliers/supplier-input';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useDeletePayments, usePaymentsDetail, useSavePayments, useUpdatePayments } from 'services/payments.service';
import { useSupplierTableData } from 'services/supplier.service';
import { showToast } from 'store/global';

interface IPaymentsForm {
  formConfig: any;
  onClose: Function;
}

const DEFAULT_FORM_DATA = {
  sid: '',
  amount: 0,
};
const PaymentsForm = ({ onClose, formConfig }: IPaymentsForm) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>(DEFAULT_FORM_DATA);
  const [formError, setFormError] = useState<any>({});
  const supplierList = useSupplierTableData(allSuppliersPayload);
  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);

  const inputRef: any = useRef(null);
  const { data: paymentsDetailResponse, isFetching } = usePaymentsDetail(formConfig.formId);

  const savePayments = useSavePayments();
  const updatePayments = useUpdatePayments();
  const deletePayments = useDeletePayments();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, [inputRef]);

  useEffect(() => {
    if (paymentsDetailResponse) {
      setFormData({ ...DEFAULT_FORM_DATA, ...paymentsDetailResponse });
    }
    return () => {
      setFormData({ ...DEFAULT_FORM_DATA });
    };
  }, [paymentsDetailResponse]);

  const setInputValue = (event: any, field: string) => {
    event.preventDefault();
    const value = event.target.value;
    setFormData({ ...formData, [field]: value });
    setFormError({ ...formError, [field]: '' });
  };

  const setNumberInputValue = (event: any, field: string) => {
    event.preventDefault();
    const value = event.target.value;
    const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (value === '' || re.test(value)) {
      setFormData({ ...formData, [field]: value });
    }
    setFormError({ ...formError, [field]: '' });
  };

  const onSave = () => {
    savePayments.mutate(formData, {
      onSuccess: response => {
        dispatch(showToast({ type: 'success', message: 'Payments created' }));
        queryClient.invalidateQueries({ queryKey: [apiRoutes.PAYMENTS_LIST], refetchActive: true });
        queryClient.invalidateQueries({ queryKey: [apiRoutes.SUPPLIER_LIST], refetchActive: true });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  const onUpdate = () => {
    updatePayments.mutate(formData, {
      onSuccess: () => {
        dispatch(showToast({ type: 'success', message: 'Payments details updated' }));
        queryClient.invalidateQueries({ queryKey: [apiRoutes.PAYMENTS_LIST], refetchActive: true });
        queryClient.invalidateQueries({ queryKey: [apiRoutes.SUPPLIER_LIST], refetchActive: true });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  const onDelete = () => {
    toggleDeleteConfirmation(false);
    deletePayments.mutate(formData.pu_id, {
      onSuccess: () => {
        dispatch(showToast({ type: 'warning', message: 'Payments details deleted' }));
        queryClient.invalidateQueries({
          queryKey: [apiRoutes.PAYMENTS_LIST, 'usePaymentsTableData'],
          refetchActive: true,
        });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  return (
    <>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={
            savePayments.isLoading ||
            updatePayments.isLoading ||
            deletePayments.isLoading ||
            isFetching ||
            supplierList.isFetching
          }
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <MainCard
          sx={{ width: 500 }}
          title={formConfig.formType === globalConstants.FORM_TYPE.CREATE ? 'Payments Entry' : 'Update Payments'}
          secondary={
            formConfig.formType === globalConstants.FORM_TYPE.CREATE ? (
              <ModalBoxstyles.ActionContainer>
                <Button variant="contained" size="small" startIcon={<SaveIcon />} onClick={onSave}>
                  Save
                </Button>
                <Button color="secondary" size="small" startIcon={<CancelIcon />} onClick={() => onClose()}>
                  Cancel
                </Button>
              </ModalBoxstyles.ActionContainer>
            ) : (
              <ModalBoxstyles.ActionContainer>
                <Button color="warning" variant="contained" size="small" startIcon={<UpdateIcon />} onClick={onUpdate}>
                  Update
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => toggleDeleteConfirmation(true)}
                >
                  Delete
                </Button>
                <Button color="secondary" size="small" startIcon={<CancelIcon />} onClick={() => onClose()}>
                  Cancel
                </Button>
              </ModalBoxstyles.ActionContainer>
            )
          }
        >
          <Grid item xs={6} md={12} sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', gap: 2 }}>
              <SupplierInput
                value={formData.sid}
                onChange={(event: any) => setInputValue(event, 'sid')}
                error={formError.sid?.length > 0}
                helperText={formError.sid}
              />
              <TextField
                label="Amount"
                value={formData.amount}
                autoComplete="off"
                error={formError.amount?.length > 0}
                helperText={formError.amount}
                onChange={event => setNumberInputValue(event, 'amount')}
              />
              <TextField
                label="Notes"
                value={formData.notes}
                autoComplete="off"
                error={formError.notes?.length > 0}
                helperText={formError.notes}
                onChange={event => setInputValue(event, 'notes')}
              />
            </Box>
          </Grid>
        </MainCard>
      </div>
      <Dialog open={showDeleteConfirmation} onClose={() => toggleDeleteConfirmation(false)}>
        <DialogTitle>{'Are you sure to Delete?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Once delete you can't restore data!.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={onDelete} autoFocus color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentsForm;
