import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  Grid,
  TextField,
} from '@mui/material';
import MainCard from 'components/MainCard';
import ModalBoxstyles from 'components/modal-box/styles';
import { STATUS_CODES, apiRoutes } from 'constants/api.constant';
import globalConstants from 'constants/global.constants';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import {
  useDeleteProduct,
  useFetchCategory,
  useProductDetail,
  useSaveProduct,
  useUpdateProduct,
} from 'services/product.service';
import { showToast } from 'store/global';
import CategoryList from './category-list';
import ProductFormStyles from './styles';

interface IProductForm {
  formConfig: any;
  onClose: Function;
}

const DEFAULT_FORM_DATA = {
  name: '',
  code: '',
  cat_id: '',
  hsn: '00',
  gst: 0,
  rate: 0,
};
const ProductForm = ({ onClose, formConfig }: IProductForm) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>(DEFAULT_FORM_DATA);
  const [formError, setFormError] = useState<any>({});
  const [isCategoryFormVisible, setCategoryFormVisible] = useState<boolean>(false);
  const [showDeleteConfirmation, toggleDeleteConfirmation] = useState(false);

  const inputRef: any = useRef(null);
  const { data: productDetailResponse, isFetching } = useProductDetail(formConfig.formId);
  const { data: categoryList = { results: [] } } = useFetchCategory();
  const categoryName = useMemo(
    () => categoryList.results.find((i: any) => i.cat_id === formData.cat_id)?.name,
    [categoryList.results, formData.cat_id],
  );

  const saveProduct = useSaveProduct();
  const updateProduct = useUpdateProduct();
  const deleteProduct = useDeleteProduct();

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, [inputRef]);

  useEffect(() => {
    if (productDetailResponse) {
      setFormData({ ...DEFAULT_FORM_DATA, ...productDetailResponse, gst: 0, hsn: '00' });
    }
    return () => {
      setFormData({ ...DEFAULT_FORM_DATA });
    };
  }, [productDetailResponse]);

  const setInputValue = (event: any, field: string) => {
    event.preventDefault();
    const value = event.target.value;
    setFormData({ ...formData, [field]: value });
    setFormError({ ...formError, [field]: '' });
  };

  // const onChangeGst = (gst: number) => {
  //   let cost = parseFloat(formData.cost) || 0;
  //   let rate = parseFloat((cost + cost * (gst / 100)).toFixed(2));
  //   let tax_value = rate - cost;
  //   setFormData({ ...formData, gst: gst, rate: rate, cost: cost, tax_value: tax_value });
  //   setFormError({ ...formError, cost: '', rate: '', gst: '' });
  // };

  const setNumberInputValue = (event: any, field: string) => {
    event.preventDefault();
    const value = event.target.value;
    const re = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (value === '' || value === '-' || re.test(value)) {
      const gst = formData.gst || 0;
      let cost = 0;
      let rate = 0;
      let tax_value = 0;
      if (field === 'cost') {
        cost = parseFloat(value) || 0;
        rate = parseFloat((cost + cost * (gst / 100)).toFixed(2));
        tax_value = rate - cost;
      } else {
        rate = parseFloat(value) || 0;
        cost = parseFloat((rate / (1 + gst / 100)).toFixed(2));
        tax_value = rate - cost;
      }
      setFormData({ ...formData, rate: rate, cost: cost, tax_value: tax_value });
    }
    setFormError({ ...formError, cost: '', rate: '' });
  };

  const onSave = () => {
    saveProduct.mutate(formData, {
      onSuccess: response => {
        dispatch(showToast({ type: 'success', message: 'Product created' }));
        queryClient.invalidateQueries({ queryKey: [apiRoutes.PRODUCT_LIST], refetchActive: true });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  const onUpdate = () => {
    updateProduct.mutate(formData, {
      onSuccess: () => {
        dispatch(showToast({ type: 'success', message: 'Product details updated' }));
        queryClient.invalidateQueries({ queryKey: [apiRoutes.PRODUCT_LIST], refetchActive: true });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  const onDelete = () => {
    toggleDeleteConfirmation(false);
    deleteProduct.mutate(formData.sid, {
      onSuccess: () => {
        dispatch(showToast({ type: 'warning', message: 'Product details deleted' }));
        queryClient.invalidateQueries({
          queryKey: [apiRoutes.PRODUCT_LIST, 'useProductTableData'],
          refetchActive: true,
        });
        onClose();
      },
      onError: (error: any) => {
        if (error.status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
          setFormError(error.data.errors);
        }
      },
    });
  };

  return (
    <>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={saveProduct.isLoading || updateProduct.isLoading || deleteProduct.isLoading || isFetching}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        <MainCard
          sx={{ width: 500 }}
          title={
            formConfig.formType === globalConstants.FORM_TYPE.CREATE ? 'Create product form' : 'Update product form'
          }
          secondary={
            formConfig.formType === globalConstants.FORM_TYPE.CREATE ? (
              <ModalBoxstyles.ActionContainer>
                <Button variant="contained" size="small" startIcon={<SaveIcon />} onClick={onSave}>
                  Save
                </Button>
                <Button color="secondary" size="small" startIcon={<CancelIcon />} onClick={() => onClose()}>
                  Cancel
                </Button>
              </ModalBoxstyles.ActionContainer>
            ) : (
              <ModalBoxstyles.ActionContainer>
                <Button color="warning" variant="contained" size="small" startIcon={<UpdateIcon />} onClick={onUpdate}>
                  Update
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => toggleDeleteConfirmation(true)}
                >
                  Delete
                </Button>
                <Button color="secondary" size="small" startIcon={<CancelIcon />} onClick={() => onClose()}>
                  Cancel
                </Button>
              </ModalBoxstyles.ActionContainer>
            )
          }
        >
          <Grid item xs={6} md={12} sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', gap: 2 }}>
              <TextField
                label="Product Name"
                value={formData.name}
                autoComplete="off"
                inputRef={inputRef}
                error={formError.name?.length > 0}
                helperText={formError.name}
                onChange={event => setInputValue(event, 'name')}
              />
              <TextField
                label="Code"
                value={formData.code}
                autoComplete="off"
                error={formError.code?.length > 0}
                helperText={formError.code}
                onChange={event => setInputValue(event, 'code')}
              />
              <ProductFormStyles.CategoryInput onClick={() => setCategoryFormVisible(true)}>
                <TextField
                  InputLabelProps={{ shrink: !!categoryName }}
                  className="disabledTextField"
                  label="Category"
                  value={categoryName || ''}
                  autoComplete="off"
                  error={formError.cat_id?.length > 0}
                  helperText={formError.cat_id}
                  inputProps={{ readOnly: true }}
                />
              </ProductFormStyles.CategoryInput>
              {/* 
              <TextField
                label="HSN"
                value={formData.hsn}
                autoComplete="off"
                error={formError.hsn?.length > 0}
                helperText={formError.hsn}
                onChange={event => setInputValue(event, 'hsn')}
              /> */}
              {/* <ProductFormStyles.ChipInput>
                <InputLabel htmlFor="my-input">GST</InputLabel>
                <ProductFormStyles.ChipContainer>
                  {[0, 2, 5, 12, 18, 28].map((i: any) => (
                    <Chip
                      key={i}
                      label={`${i}%`}
                      variant={i === formData.gst ? 'filled' : 'outlined'}
                      color={i === formData.gst ? 'primary' : 'secondary'}
                      clickable
                      onClick={() => onChangeGst(i)}
                    />
                  ))}
                </ProductFormStyles.ChipContainer>
                <FormHelperText error={formError.gst?.length > 0}>{formError.gst}</FormHelperText>
              </ProductFormStyles.ChipInput> */}
              {/* <TextField
                label="Original Cost"
                value={formData.cost}
                autoComplete="off"
                InputLabelProps={{ shrink: formData.cost?.toString().length }}
                error={formError.cost?.length > 0}
                helperText={formError.cost}
                onChange={event => setNumberInputValue(event, 'cost')}
              /> */}
              <TextField
                label="Selling Price"
                value={formData.rate}
                autoComplete="off"
                error={formError.rate?.length > 0}
                helperText={formError.rate}
                onChange={event => setNumberInputValue(event, 'rate')}
              />
            </Box>
          </Grid>
        </MainCard>
      </div>
      <Dialog open={showDeleteConfirmation} onClose={() => toggleDeleteConfirmation(false)}>
        <DialogTitle>{'Are you sure to Delete?'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Once delete you can't restore data!.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDeleteConfirmation(false)}>Cancel</Button>
          <Button onClick={onDelete} autoFocus color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        variant="temporary"
        open={isCategoryFormVisible}
        ModalProps={{ keepMounted: true }}
        anchor="right"
        onClose={() => setCategoryFormVisible(false)}
      >
        {isCategoryFormVisible && (
          <CategoryList
            onClose={(value: any) => {
              setFormData(value);
              setCategoryFormVisible(false);
            }}
            formData={formData}
          />
        )}
      </Drawer>
    </>
  );
};

export default ProductForm;
