// @ts-nocheck
import { addRxPlugin, createRxDatabase } from 'rxdb';
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup';
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election';
import { replicateRxCollection } from 'rxdb/plugins/replication';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import apiClient, { BASE_URL } from 'services/api.service';
import { fetchOrderNo_db } from 'services/bills.service';
import store from 'store';
import { setOrderNo } from 'store/bills';
import { billsSchema, categorySchema, productsSchema, userSchema } from './schema';
addRxPlugin(RxDBCleanupPlugin);

addRxPlugin(RxDBLeaderElectionPlugin);

const syncURL = `${BASE_URL}sync/`;
let dbPromise: any = null;
export const broadToken: any = Math.random();

const _create = async () => {
  const db = await createRxDatabase({
    name: 'chaaiBunkUiDb',
    storage: getRxStorageDexie(),
  });
  let win: any = window;
  win['db'] = db; // write to window for debugging

  // show leadership in title
  db.waitForLeadership().then(() => {
    console.log('isLeader now');
    document.title = '♛ ' + document.title;
  });

  // create collections
  await db.addCollections({
    products: {
      schema: productsSchema,
    },
    category: {
      schema: categorySchema,
    },
    users: {
      schema: userSchema,
    },
    bills: {
      schema: billsSchema,
    },
  });

  return db;
};

const get = () => {
  if (!dbPromise) dbPromise = _create();
  return dbPromise;
};

const syncProducts = () => {
  // sync
  ['products', 'category'].forEach(colName => {
    const replicationState = replicateRxCollection({
      collection: db[colName],
      replicationIdentifier: 'my-http-replication',
      live: true,
      pull: {
        async handler(checkpointOrNull: any, batchSize) {
          const updatedAt = checkpointOrNull ? checkpointOrNull.updatedAt : 0;
          const id = checkpointOrNull ? checkpointOrNull.id : '';
          const data = await apiClient.get(`${syncURL}pull`, {
            params: {
              collection: colName,
              updatedAt: updatedAt,
              id: id,
              limit: batchSize,
            },
          });
          return {
            documents: data.documents,
            checkpoint: data.checkpoint,
          };
        },
      },
      autoStart: true,
    });
    replicationState.error$.subscribe(err => {
      console.log('Got replication error:');
      console.dir(err);
    });
  });
  syncBills();
};

const syncBills = () => {
  const colName = 'bills';
  const replicationState = replicateRxCollection({
    collection: db[colName],
    replicationIdentifier: 'my-http-replication',
    live: true,
    pull: {
      async handler(checkpointOrNull: any, batchSize) {
        const updatedAt = checkpointOrNull ? checkpointOrNull.updatedAt : 0;
        const id = checkpointOrNull ? checkpointOrNull.id : '';
        const data = await apiClient.get(`${syncURL}pull`, {
          params: {
            collection: colName,
            updatedAt: updatedAt,
            id: id,
            limit: batchSize,
          },
        });
        if (data.documents.length === 0) {
          const order = await fetchOrderNo_db();
          store.dispatch(setOrderNo(order));
        }
        return {
          documents: data.documents,
          checkpoint: data.checkpoint,
        };
      },
    },
    autoStart: true,
  });
  replicationState.error$.subscribe(err => {
    console.log('Got replication error:');
    console.dir(err);
  });
};

const Database = { get, syncProducts };
export default Database;
