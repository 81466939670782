export const productsSchema = {
  title: 'productsSchema',
  version: 0,
  description: '',
  primaryKey: 'pid',
  type: 'object',
  properties: {
    pid: {
      type: 'number',
      maxLength: 100,
    },
    name: {
      type: 'string',
    },
    code: {
      type: 'string',
    },
    cat_id: {
      type: 'string',
    },
    hsn: {
      type: 'string',
    },
    gst: {
      type: 'string',
    },
    cost: {
      type: 'string',
    },
    rate: {
      type: 'string',
    },
    tax_value: {
      type: 'string',
    },
  },
};
export const categorySchema = {
  title: 'categorySchema',
  version: 0,
  description: '',
  primaryKey: 'cat_id',
  type: 'object',
  properties: {
    cat_id: {
      type: 'number',
      maxLength: 100,
    },
    name: {
      type: 'string',
    },
    code: {
      type: 'string',
    },
    img: {
      type: 'string',
    },
  },
};

export const userSchema = {
  title: 'userSchema',
  version: 0,
  description: '',
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'number',
      maxLength: 100,
    },
    name: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    role: {
      type: 'string',
    },
    tenant: {
      type: 'string',
    },
    pwd: {
      type: 'string',
    },
    token: {
      type: 'string',
    },
  },
};

export const billsSchema = {
  title: 'billsSchema',
  version: 0,
  description: '',
  primaryKey: 'bid',
  type: 'object',
  properties: {
    bid: {
      type: 'string',
      maxLength: 100,
    },
    amount: {
      type: 'string',
    },
    changed_by: {
      type: 'string',
    },
    order_no: {
      type: 'string',
    },
    created_at: {
      type: 'string',
    },
    changed_at: {
      type: 'string',
    },
    qty: {
      type: 'string',
    },
    total_cost: {
      type: 'string',
    },
    total_tax: {
      type: 'string',
    },
    local_data: { type: 'number' },
    items: {
      type: 'array',
    },
  },
};
