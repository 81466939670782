import { Button } from '@mui/material';
import { appRoutes } from 'constants/routes.constant';
import { useNavigate } from 'react-router-dom';
import AppStateStyles from './styles';
const PageError = () => {
  const navigate = useNavigate();
  return (
    <>
      <AppStateStyles.Container>
        <img src={'/assets/images/500.png'} alt="svg" />
        <Button variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => navigate(appRoutes.LANDING)}>
          Back to home
        </Button>
      </AppStateStyles.Container>
    </>
  );
};

export default PageError;
