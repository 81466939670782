import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSupplierTableData } from 'services/supplier.service';
import { allSuppliersPayload, defaultFormData } from './config';
import SupplierInputStyles from './styles';
import SupplierForm from './supplier-form';

const filter = createFilterOptions();

interface ISupplierInput {
  value: number;
  onChange: Function;
  error: boolean;
  helperText: string;
}

const SupplierInput = ({ value, onChange, error, helperText }: ISupplierInput) => {
  const { data = { results: [] } } = useSupplierTableData(allSuppliersPayload);
  const [formConfig, setFormConfig] = useState<any>(defaultFormData);
  const selectedValue = useMemo(() => data.results.find((i: any) => i.sid === value) || null, [value, data]);

  return (
    <>
      <SupplierInputStyles.AutoCompleteStyles />
      <Autocomplete
        className="inputAC"
        value={selectedValue}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              setFormConfig({ ...defaultFormData, isOpen: true, formData: { name: newValue } });
            });
          } else if (newValue && newValue.inputValue) {
            setFormConfig({ ...defaultFormData, isOpen: true, formData: { name: newValue.inputValue } });
          } else {
            onChange({ preventDefault: () => {}, target: { value: !!newValue?.sid ? newValue.sid : '' } });
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add New Supplier "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        id="free-solo-dialog-demo"
        options={data.results}
        getOptionLabel={option => {
          // for example value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        autoHighlight
        renderInput={params => <TextField {...params} label="Select Supplier" error={error} helperText={helperText} />}
      />
      {formConfig.isOpen && (
        <SupplierForm
          onClose={(sid: number) => {
            onChange({ preventDefault: () => {}, target: { value: !!sid ? sid : '' } });
            setFormConfig(defaultFormData);
          }}
          formConfig={formConfig}
        />
      )}
    </>
  );
};

export default SupplierInput;
