// material-ui
import { createTheme } from '@mui/material/styles';

// third-party
import { presetPalettes } from '@ant-design/colors';
import componentsOverride from './overrides';
import CustomShadows from './shadows';
import Typography from './typography';

// project import
import ThemeOption from './theme';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = mode => {
  const colors = presetPalettes;

  const greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000',
  ];
  const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  const greyConstant = ['#fafafb', '#e6ebf1'];

  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const paletteColor = ThemeOption(colors);

  const theme =
    mode === 'light'
      ? createTheme({
          palette: {
            mode,
            primary: {
              main: '#006738',
            },
            secondary: {
              main: '#ffaa00',
            },
            common: {
              black: '#000',
              white: '#fff',
            },
            ...paletteColor,
            text: {
              primary: '#000',
              secondary: paletteColor.grey[500],
              disabled: paletteColor.grey[400],
            },
            action: {
              disabled: paletteColor.grey[300],
            },
            divider: paletteColor.grey[200],
            background: {
              paper: paletteColor.grey[0],
              default: '#eef3f8',
            },
          },
        })
      : createTheme({
          palette: {
            mode,
            primary: {
              main: '#006738',
            },
            secondary: {
              main: '#ffaa00',
            },
            common: {
              black: '#000',
              white: '#fff',
            },
            ...paletteColor,
            text: {
              primary: '#e8eaed',
            },
            background: {
              paper: '#1e1e1e',
              default: '#121212',
            },
          },
        });

  // const theme =
  //   mode === 'light'
  //     ? createTheme({
  //         palette: {
  //           mode,
  //           primary: {
  //             main: '#006738',
  //           },
  //           secondary: {
  //             main: '#ffaa00',
  //           },
  //         },
  //       })
  //     : createTheme({
  //         palette: {
  //           mode,
  //           primary: {
  //             main: '#006738',
  //           },
  //           secondary: {
  //             main: '#ffaa00',
  //           },
  //         },
  //       });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography = Typography(`'Adobe Clean UX', sans-serif`);
  const themeCustomShadows = CustomShadows(theme);

  const themeOptions = {
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1266,
        xl: 1536,
      },
    },
    direction: 'ltr',
    mixins: {
      toolbar: {
        minHeight: 50,
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    palette: theme.palette,
    customShadows: themeCustomShadows,
    typography: themeTypography,
  };

  const themes = createTheme(themeOptions);
  themes.components = componentsOverride(themes);
  return themes;
};

export default Palette;
