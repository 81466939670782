export interface ITablePayload {
  fields: string[];
  conditions: IFilterCondition[];
  orderBy: IOrderBy;
  pagination?: IPagination;
}
export enum IOrderByDirections {
  ASC = 'asc',
  DESC = 'desc',
}
export interface IOrderBy {
  field: string;
  direction: IOrderByDirections;
}
export interface IPagination {
  skip: number;
  take: number;
}
export interface IFilterCondition {
  field: string;
  condition: string;
  value: string;
}
