import styled, { keyframes } from 'styled-components';

const flyAnimation = keyframes`
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  gap: 50px;
  justify-content: center;
  background-image: linear-gradient(to top left, rgba(15, 15, 15, 0.5), rgba(0, 0, 0, 0.7), rgba(10, 10, 10, 0.5));
  img {
    height: 60%;
    animation: ${flyAnimation} 4s linear infinite;
  }
`;

const AppStateStyles = {
  Container,
};

export default AppStateStyles;
