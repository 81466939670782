import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export type GlobalState = {
  toast: ToastState;
  isDarkTheme: boolean;
};
export type ToastState = {
  isOpen: boolean;
  type: 'success';
  message: string;
};
//
export const initialState: GlobalState = {
  toast: { isOpen: false, type: 'success', message: '' },
  isDarkTheme: !!localStorage.getItem('isDarkTheme'),
};
// A slice
const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<any>) => {
      return { ...state, toast: { ...action.payload, isOpen: true } };
    },
    hideToast: state => {
      return { ...state, toast: { ...state.toast, isOpen: false } };
    },
    toggleThemeMode: state => {
      if (state.isDarkTheme) {
        localStorage.removeItem('isDarkTheme');
      } else {
        localStorage.setItem('isDarkTheme', 'true');
      }
      return { ...state, isDarkTheme: !state.isDarkTheme };
    },
  },
});

// Actions generated from the slice
export const { showToast, hideToast, toggleThemeMode } = globalSlice.actions;

// export user selector to get the slice in any component
export const globalSelector = (state: RootState) => state.global;
// export The reducer
const globalReducer = globalSlice.reducer;
export default globalReducer;
