import axios, { InternalAxiosRequestConfig } from 'axios';
import store from '../store';

export const BASE_URL = `${window.location.protocol}//chaibunk-api.lobatelinternetservices.in/api/`;
// export const BASE_URL = 'http://localhost:8000/api/';

const injectToken = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  let newConfig = { ...config };
  try {
    const { token } = store.getState().auth;
    if (token && newConfig.headers) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }
    return newConfig;
  } catch (error: any) {
    throw new Error(error);
  }
};

const apiClient = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    Accept: 'application/json',
  },
});

apiClient.interceptors.request.use(injectToken, async error => {
  return Promise.reject(error);
});

apiClient.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    return Promise.reject(error.response);
  },
);

export default apiClient;
