import { useMutation, useQuery } from 'react-query';
import { apiRoutes } from '../constants/api.constant';
import apiClient from './api.service';
import { ITablePayload } from './types';

export const usePurchaseTableData = (payload: ITablePayload): any => {
  return useQuery([apiRoutes.PURCHASE_LIST, 'usePurchaseTableData', payload], () => getPurchaseTableData(payload));
};

export const usePurchaseDetail = (purchaseId: number): any => {
  return useQuery([apiRoutes.PURCHASE_LIST, 'usePurchaseDetail', purchaseId], () => getPurchaseDetail(purchaseId), {
    enabled: !!purchaseId,
  });
};

export const useSavePurchase = () => useMutation(savePurchase);
export const useUpdatePurchase = () => useMutation(updatePurchase);
export const useDeletePurchase = () => useMutation(deletePurchase);

const getPurchaseTableData = async (payload: any) => await apiClient.post(apiRoutes.PURCHASE_LIST, payload);
const getPurchaseDetail = async (purchaseId: number) => await apiClient.get(`${apiRoutes.PURCHASE}/${purchaseId}`);
const savePurchase = async (data: any) => await apiClient.post(apiRoutes.PURCHASE, data);
const updatePurchase = async (data: any) => await apiClient.post(`${apiRoutes.PURCHASE}/${data.formId}`, data);
const deletePurchase = async (purchaseId: number) => await apiClient.delete(`${apiRoutes.PURCHASE}/${purchaseId}`);
