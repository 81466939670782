import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRole } from 'constants/global.constants';
import { RootState } from '.';
// import {AppDispatch, AppThunk} from '../store';

export type AuthState = {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  tenant: string;
  token: string;
  loggedIn: boolean;
  loginVerified: boolean;
  permissions: any;
};
//
export const initialState: AuthState = {
  id: '',
  name: '',
  email: '',
  role: UserRole.EMPTY,
  tenant: '',
  token: '',
  loggedIn: false,
  loginVerified: false,
  permissions: {},
};
// A slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthDetails: (state, action: PayloadAction<Partial<AuthState>>) => {
      const newState = { ...state, ...action.payload };
      return { ...newState, permissions: getPermissions(newState.role) };
    },
    // getUsersSuccess: (state, action: PayloadAction<User[]>) => {
    //     state.users = action.payload;
    //     state.loading = false;
    //     state.hasErrors = false;
    // },
  },
});
const getPermissions = (role: UserRole) => {
  return {
    addProduct: role === UserRole.ADMIN,
    addPurchase: role === UserRole.ADMIN,
    addPayments: role === UserRole.ADMIN,
  };
};
// Actions generated from the slice
export const { setAuthDetails } = authSlice.actions;

// export user selector to get the slice in any component
export const authSelector = (state: RootState) => state.auth;
// export The reducer
const authReducer = authSlice.reducer;
export default authReducer;
// Actions
// export const fetchUsers = (): AppThunk => async (dispatch: AppDispatch) => {
//     try {
//         dispatch(startLoading());
//         const response = await fetch(
//             'https://jsonplaceholder.typicode.com/users',
//         );
//         const data: User[] = await response.json();
//         dispatch(getUsersSuccess(data));
//     } catch (error) {
//         dispatch(getUsersFailure());
//     }
// };

// export const createUser = (User: User): AppThunk => async (
//     dispatch: AppDispatch,
// ) => {
//     try {
//         dispatch(addUser(User));
//     } catch (error) {
//         dispatch(getUsersFailure());
//     }
// };
